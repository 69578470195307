import {
  BrowserRouter,
  Route,
  Router,
  Routes
} from "react-router-dom";
import React, {useState,useEffect} from 'react';
import './App.css';
import  LoginPage from './views/LoginPage.js';
import SignupWithRouter from './views/Signup.js'
import UserProfile from './views/UserProfile.js';
import Profile from './views/Profile'
import AdminProfile from './views/AdminProfile'
import Mayersresults from './views/MayersBiggerResult'
import Passwordreset from './views/Passwordreset'
import Forgot from "./views/Forgot";
import Changepassword from "./views/ChangePassword";
import Training from "./views/Training";
import Upload from "./views/Upload";
import ResumeUpload from "./views/ResumeUpload";
import Creataccount from "./admin/creataccount";
import Admindashboardveterans from "./admin/admindashboardveterans";
import Admindashboardeducator from "./admin/admindashboardeducators";
import Educatorprofile from "./admin/educatorprofile";
import Educatorcreationaccount from "./admin/educatorcreationaccount";
import Admindashboard from "./admin/admindashboard";
import AdmDashboard from "./views/admin/AdmDashboard";
import VeteranDashboardView from "./views/admin/VeteranDashboardView";
import EducatorProfile from "./views/educator/EducatorProfile.js";
import EducatorSignupWithRouter from './views/educator/EducatorSignup.js'
import EducatorDetails from "./views/educator/EducatorDetails.js";
import EducatorDashboard from "./views/educator/EducatorDashboard.js";
import EducatorVeteranDashboardView from "./views/educator/EducatorVeteranDashboardView.js";

function App() {

  useEffect(()=>{
    window.addEventListener('beforeunload', (event) => {     
      localStorage.clear();    
    });
  },[]);
  return (
    
      <BrowserRouter>
        <Routes>
          <Route exact path="/admin/user/*"  element={<UserProfile/>}/>
          <Route exact path="/admin/Profile/"  element={<Profile/>}/>
          <Route exact path="/admin/Training/"  element={<Training/>}/>
          <Route exact path="/admin/Upload/"  element={<Upload/>}/>
          <Route exact path="/admin/ResumeUpload/"  element={<ResumeUpload/>}/>
          <Route exact path="Mayersresults"  element={<Mayersresults/>} />
          <Route exact path="Passwordreset" element={<Passwordreset />} />
          <Route exact path="/admin/changepassword" element={<Changepassword />} />
          
          <Route exact path="/adminuser/AdminProfile/"  element={<AdminProfile/>}/>
          <Route exact path="/admin/AdmDashboard/"  element={<AdmDashboard/>}/>
          <Route exact path="/admin/VeteranDashboardView" element={<VeteranDashboardView/>}/> 

          <Route exact path="/educator/EducatorVeteranDashboardView/"  element={<EducatorVeteranDashboardView/>}/>
          <Route exact path="/educator/EducatorDashboard/"  element={<EducatorDashboard/>}/>
          <Route exact path="/educator/profile/"  element={<EducatorProfile/>}/>
          <Route exact path="/educator/details/"  element={<EducatorDetails/>}/>
          <Route exact path="EducatorSignup"  element={<EducatorSignupWithRouter/>}/>
          <Route exact path="/educator/changepassword" element={<Changepassword />} />

          {/* </Route> */}
          <Route exact path="/*" element={<LoginPage />}/>
          <Route exact path="Forgot" element={<Forgot />} />
          <Route exact path="Signup"  element={<SignupWithRouter/>}/>
          <Route exact path="admin/Creataccount" element={<Creataccount/>}/>
          <Route exact path="admin/admindashboardveterans" element={<Admindashboardveterans/>}/>
          <Route exact path="admin/admindashboardeducator" element={<Admindashboardeducator/>}/>
          <Route exact path="admin/Educatorcreationaccount" element={<Educatorcreationaccount/>}/>
          <Route exact path="admin/educationprofile" element={<Educatorprofile/>}/>
          <Route exact path="admin/admindashboard" element={<Admindashboard/>}/>          
                  
        </Routes>
      </BrowserRouter>      
      
  );
}

export default App;
