
import React, { useEffect, useState } from 'react'
import Sidebar from "../component/Sidebar/Sidebar";
import AdminNavbar from "../component/Navbars/AdminNavbar";
import routes from "../routes.js";
import { useNavigate, Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { updateTrainingData, viewTrainingData } from '../service/UserService.js';

function Training(props) {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const [values, setValues] = useState({
        id: 0,
        trainingOne: '',
        trainingTwo: '',
        trainingThree: '',
        experienceOne: '',
        experienceTwo: '',
        experienceThree: '',
    });

    useEffect(() => {

        var Key = localStorage.getItem('USER_KEY');
        if (Key != undefined) {

        } else {
            window.location.href = "../login";
        }
        var name = cookies.get('userName')
        viewTrainingData('?userName=' + name).then((response) => {
            console.log("response", response);
            if (response.status === 200) {
                setValues(prev => ({
                    ...prev,
                    id: response.data.id,
                    trainingOne: response.data.training1,
                    trainingTwo: response.data.training2,
                    trainingThree: response.data.training3,
                    experienceOne: response.data.experience1,
                    experienceTwo: response.data.experience2,
                    experienceThree: response.data.experience3,
                }));
            }
        }).catch((err) => {

            if (err && err.response) {

                switch (err.response.status) {
                    case 401:
                        console.log("401 status");
                        break;
                    default:

                }
            }

        });
        setValues(prev => ({
            ...prev,
            userName: name
        }));

    }, [])

    const handleSubmit = (evt) => {
        evt.preventDefault();
        const trainingValues = {

            userName: values.userName,
            id: 0,
            training1: values.trainingOne,
            training2: values.trainingTwo,
            training3: values.trainingThree,
            experience1: values.experienceOne,
            experience2: values.experienceTwo,
            experience3: values.experienceThree
        }
        updateTrainingData(JSON.stringify(trainingValues)).then((response) => {
            console.log("response", response);
            if (response.status === 200) {
                document.getElementById("show").style.display = "block";
            }

        }).catch((err) => {

            if (err && err.response) {

                switch (err.response.status) {
                    case 401:
                        console.log("401 status");
                        //props.loginFailure("Authentication Failed.Bad Credentials");
                        break;
                    default:
                    //props.loginFailure('Something Wrong!Please Try Again');

                }

            }
            else {
                //props.loginFailure('Something Wrong!Please Try Again');
            }
        });

    }
    const handleChange = (e) => {
        e.persist();
        setValues(values => ({
            ...values,
            [e.target.name]: e.target.value
        }));
    };
    const onClickOk = e => {
        document.getElementById("show").style.display = "none";
    }
    return (
        <>
            <div className="wrapper">
                <Sidebar color={"black"} image={""} routes={routes} />
                <div className="main-panel" ref={null}>
                    <AdminNavbar />
                    <div className="container-bg">
                        <h2 className="wel-txt">Training and Experience</h2>
                        <form onSubmit={handleSubmit}>
                            <p className="training-headding">Training Obtained not part of any MOS</p>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Training #1</label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" name="trainingOne" value={values.trainingOne} onChange={handleChange} rows="3" placeholder="Training #1"></textarea>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Training #2</label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" name="trainingTwo" value={values.trainingTwo} onChange={handleChange} rows="3" placeholder="Training #2"></textarea>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Training #3</label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" name="trainingThree" value={values.trainingThree} onChange={handleChange} rows="3" placeholder="Training #3"></textarea>
                            </div>
                            <p className="training-headding">Experience Obtained not part of any MOS</p>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Experience #1</label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" name="experienceOne" value={values.experienceOne} onChange={handleChange} rows="3" placeholder="Experience #1"></textarea>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Experience #2</label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" name="experienceTwo" value={values.experienceTwo} onChange={handleChange} rows="3" placeholder="Experience #2"></textarea>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Experience #3</label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" name="experienceThree" value={values.experienceThree} onChange={handleChange} rows="3" placeholder="Experience #3"></textarea>
                            </div>
                            <div className="custom-btn-block">
                                <button type="submit" className="btn btn-primary update-btn">Save</button>
                                <div id="show" style={{ display: "done" }}><div>Changes got saved succesfully. </div>
                                    <a onClick={onClickOk}>OK</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>

    )
}
export default Training;
