import React from 'react'
function Mayersresults(){
    return(

        <>
<div className="wrapper">
    <div className="container-bg">
    <h2 className="wel-txt">Meyers Briggs Results</h2>
        <div>
            <div className="custom-btn-block ptbnt"><button type="button" className="btn custom-btn-large">Download</button></div>
            <div className="form-group">
            <label for="exampleFormControlTextarea1">Industries / Jobs based off results</label>
            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Industries / Jobs based off results"></textarea>
            </div>
        </div>


    </div>
</div>
        
        </>
    )

}

export default Mayersresults;