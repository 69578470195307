import React, { useState, useEffect } from "react";
import { useNavigate, Link, input } from "react-router-dom";
import { Alert, Spinner } from "react-bootstrap";
import Sidebar from "../../component/Sidebar/Sidebar";
import EducatorNavbar from "../../component/Navbars/EducatorNavbar";
import educatorroutes from "./educatorroutes.js";
import Cookies from "universal-cookie";
import {
  updateEducatorUserData,
  states,
  viewEducatorUserData,
} from "../../service/UserService.js";

export default function EducatorDetails(props) {
  const navigate = useNavigate();
  var validValue = true;
  const cookies = new Cookies();
  var responseData;

  const [input, setInput] = useState({
    institutionName: "",
    website: "",
    schoolProfile: "",
    emailId: "",
    phoneNumber: "",
    address1: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const [arrayData, SetArrayData] = useState({
    statesData: [],
    cityData: [],
  });
  
  const [error, setError] = useState({
    institutionName: "",
    website: "",
    schoolProfile: "",
    emailId: "",
    phoneNumber: "",
    city: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    var value = document.getElementById("institutionName").value;
    await validateForm("institutionName", value);
    value = document.getElementById("website").value;
    await validateForm("website", value);
    value = document.getElementById("schoolProfile").value;
    await validateForm("schoolProfile", value);
    value = document.getElementById("phoneNumber").value;
    await validateForm("phoneNumber", value);
    value = document.getElementById("emailId").value;
    await validateForm("emailId", value);
    value = document.getElementById("city").value;
    await validateForm("city", value);
    if (!validValue) {
      return false;
    }
    const user = {
      id: input.emailId,
      institutionName: input.institutionName,
      website: input.website,
      schoolProfile: input.schoolProfile,
      emailId: input.emailId,
      phoneNumber: input.phoneNumber,
      address1: input.address1,
      city: input.city,
      state: input.state,
      zipCode: input.zipCode,
    };
    //console.log(user);

    updateEducatorUserData(JSON.stringify(user))
      .then((response) => {
        if (response.status === 200) {
          console.log("Updated");
          responseData = response.data;
          document.getElementById("show").style.display = "block";
        } else {
          console.log("Something Wrong!Please Try Again");
        }
      })
      .catch((err) => {
        if (err && err.response) {
          switch (err.response.status) {
            case 403:
              console.log("403 status");
              break;
            default:
              console.log("Something Wrong!Please Try Again");
          }
        } else {
          console.log("Something Wrong!Please Try Again");
        }
      });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    inputBlur(event);
  };
  useEffect(() => {
    var Key = localStorage.getItem("USER_KEY");
    if (Key != undefined) {
    } else {
      window.location.href = "../login";
    }

    var name = cookies.get("userName");
    var tempState;
    viewEducatorUserData("?userName=" + name).then((response) => {
      console.log("response", response);
      if (response.status === 200) {
        setInput((prev) => ({
          ...prev,
          id: "",
          institutionName: response.data.institutionName,
          website: response.data.website,
          schoolProfile: response.data.schoolProfile,
          emailId: response.data.emailId,
          phoneNumber: response.data.phoneNumber,
          address1: response.data.address1,
          city: response.data.city,
          state: response.data.state,
          zipCode: response.data.zipCode,
        }));

        if (response.data.state == "") {
          tempState = 1;
        } else {
          tempState = response.data.state;
        }

      } else {
        props.loginFailure("Something Wrong!Please Try Again");
      }
    });
    states().then((response) => {
      SetArrayData((prev) => ({
        ...prev,
        statesData: response.data,
      }));
    });

    setInput((prev) => ({
      ...prev,
      userName: name,
    }));
  }, []);

  const handleChangeZip = (e) => {
    const val = e.target.value;
    const max = 9999;
    const maxLength = max.toString().length - 1;
    if (val > max) {
      e.preventDefault();
    }
  };

  const handleChangeMobile = (e) => {
    let val = e.target.value;
    val = val.trim().replace(/[^0-9]/g, "");
    if (val.length < 4) val = val;
    if (val.length < 7) val = val.replace(/(\d{3})(\d{1})/, "$1-$2");
    if (val.length < 11) val = val.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    val = val.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    e.target.value = val;
  };
  const handleChangeYear = (e) => {
    const val = e.target.value;
    const max = 9;
    const maxLength = max.toString().length - 1;
    if (val > max) {
      e.preventDefault();
    }
  };

  const inputBlur = (e) => {
    let { name, value } = e.target;
    validateForm(name, value);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validateCity = (city) => {
    const alphabeticRegex = /^[A-Za-z\s]+$/;
    return alphabeticRegex.test(city);
  };

  const onClickOk = (e) => {
    document.getElementById("show").style.display = "none";
  };

  const validateForm = (name, value) => {
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "institutionName":
          if (!value) {
            stateObj[name] = "*please enter institution name.";
            validValue = false;
          }
          break;

        case "website":
          if (!value) {
            stateObj[name] = "*please enter website.";
            validValue = false;
          }
          break;

        case "schoolProfile":
          if (!value) {
            stateObj[name] = "*please enter school profile.";
            validValue = false;
          }
          break;

        case "phoneNumber":
          if (!value) {
            stateObj[name] = "*please enter Phone number";
            validValue = false;
          }
          break;

        case "emailId":
          if (!value) {
            stateObj[name] = "*please enter emailId";
            validValue = false;
          } else {
            if (!validateEmail(value)) {
              stateObj[name] = "Please Enter valid emailId";
              validValue = false;
            }
          }
          break;

          case "city":
          if (!value) {
            stateObj[name] = "*please enter city";
            validValue = false;
          } else {
            if (!validateCity(value)) {              
              stateObj[name] = "Please enter valid city";
              validValue = false;
            }
          }
          break;

        default:
          break;
      }
      return stateObj;
    });
  };
  return (
    <>
      <div className="wrapper">
        <Sidebar color={"black"} image={""} routes={educatorroutes} />
        <div className="main-panel" ref={null}>
          <EducatorNavbar />
          <div className="content">
            <form onSubmit={handleSubmit}>
              <div className="signup-bg">
                <h2>Educator Information</h2>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group formelement-block">
                      <label htmlFor="institutionName">Institution Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="institutionName"
                        placeholder="Institution Name"
                        value={input.institutionName}
                        onChange={handleChange}
                        onBlur={inputBlur}
                        name="institutionName"
                      />

                      <div id="errorMessage" style={{ display: "none" }}>
                        <p className="error-msg">
                          *please enter institution name
                        </p>
                      </div>
                      {error.firstName && (
                        <span className="err">{error.institutionName}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group formelement-block">
                      <label htmlFor="website">Website</label>
                      <input
                        type="text"
                        className="form-control"
                        name="website"
                        placeholder="website"
                        onChange={handleChange}
                        value={input.website}
                        id="website"
                        onBlur={inputBlur}
                      />
                      <div id="errorMessage1" style={{ display: "none" }}>
                        <p className="error-msg">*please enter website</p>
                      </div>
                      {error.lastName && (
                        <span className="err">{error.website}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group formelement-block">
                      <label htmlFor="schoolProfile">School Profile</label>
                      <input
                        type="text"
                        className="form-control"
                        name="schoolProfile"
                        placeholder="School Profile"
                        onChange={handleChange}
                        value={input.schoolProfile}
                        id="schoolProfile"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group formelement-block">
                      <label htmlFor="phoneNumber">Phone</label>
                      <input
                        type="tel"
                        className="form-control"
                        name="phoneNumber"
                        maxLength={12}
                        placeholder="Phone"
                        onChange={handleChange}
                        onKeyPress={handleChangeMobile}
                        value={input.phoneNumber}
                        id="phoneNumber"
                        onBlur={inputBlur}
                      />
                      <div id="errorMessage2" style={{ display: "none" }}>
                        <p className="error-msg">*please enter phone number</p>
                      </div>
                      {error.phoneNumber && (
                        <span className="err">{error.phoneNumber}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group formelement-block">
                      <label htmlFor="emailId">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="emailId"
                        onChange={handleChange}
                        placeholder="Email"
                        value={input.emailId}
                        id="emailId"
                        disabled
                      />
                      {/* <div id="errorMessage3"  style={{display:'none'}}><p className="error-msg">{input.isValid ? (<div></div>) : (<div>Invalid email address</div>)}</p></div> */}
                      {error.emailId && (
                        <span className="err">{error.emailId}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group formelement-block">
                      <label htmlFor="address1">Address</label>
                      <input
                        type="text"
                        className="form-control"
                        name="address1"
                        placeholder="Address1"
                        onChange={handleChange}
                        value={input.address1}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group formelement-block">
                      <label htmlFor="state">State</label>
                      <select
                        className="selectpicker des form-control"
                        data-show-subtext="false"
                        name="state"
                        data-live-search="true"
                        onChange={handleChange}
                        value={input.state}
                      >
                        {arrayData.statesData.map((d, index) => (
                          <option key={d.stateId} value={d.stateId}>
                            {d.stateName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group formelement-block">
                      <label htmlFor="city">City</label>
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        placeholder="City"
                        id="city"
                        value={input.city}
                        onChange={handleChange}
                        onBlur={inputBlur}
                      />
                      {error.city && (
                        <span className="err">{error.city}</span>
                      )} 
                      </div>                                         
                    </div>

                  <div className="col-md-4">
                    <div className="form-group formelement-block">
                      <label htmlFor="zipCode">Zipcode</label>
                      <input
                        type="number"
                        className="form-control"
                        min="0"
                        name="zipCode"
                        placeholder="Zipcode"
                        onChange={handleChange}
                        onKeyPress={handleChangeZip}
                        value={input.zipCode}
                      />
                    </div>
                  </div>

                  <div
                    className="col-md-12"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <button
                      type="submit"
                      className="btn btn-primary update-btn"
                    >
                      Update
                    </button>
                    {responseData && (
                      <Alert style={{ marginTop: "20px" }} variant="danger">
                        {this.responseData}
                      </Alert>
                    )}
                    <div className="" id="show" style={{ display: "done" }}>
                      <div>Changes got saved succesfully. </div>
                      {/* <a href={"/admin/user"} >OK</a>  */}
                      <a onClick={onClickOk}>OK</a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
