import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import React, { Component, useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import Cookies from "universal-cookie";
import { viewEducatorUserData } from "../../service/UserService.js";

function Header() {
  const navigate = useNavigate();
  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  const cookies = new Cookies();
  const [userInfo, setUser] = useState({
    userName: "",
    title: "",
  });
  useEffect(() => {
    var Key = localStorage.getItem("USER_KEY");
    if (Key != undefined) {
      var name = cookies.get("userName");
      viewEducatorUserData("?userName=" + name).then((response) => {
        console.log("response", response);
        if (response.status === 200) {
          setUser({
            ...userInfo,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            universityName: response.data.universityName
          });
        }
      });
    }
  }, []);

  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
          <Button
            variant="dark"
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
            onClick={mobileSidebarToggle}
          >
            <i className="fas fa-ellipsis-v"></i>
          </Button>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" navbar>
          <Nav.Item>
                <NavLink
                  to={"../educator/profile/"}
                  className="nav-link"
                  id={"id"}
                >
                  <p>Account</p>
                </NavLink>
              </Nav.Item>

            <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "../login";
                }}
              >
                <span className="no-icon">Log out</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
