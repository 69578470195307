import React from 'react'
import {useNavigate, Link} from 'react-router-dom';
export default function Educatorcreationaccount() {


return(
    <>
<div className="wrapper">
    <div className="signup-bg" style={{display:"flex",padding:"0"}}>
        <div className="left-pannel" style={{width:"100%"}}>
            <ul style={{margin:"0",padding:"0",listStyleType: "none"}}>
            <li><Link to="/admin/creataccount">Admin Create Account</Link></li>
            <li><Link to="/admin/admindashboard" target="_self">Admin Dashboard</Link></li>
            <li><Link to="/admin/admindashboardveterans" target="_self">Veterans Dashboard</Link></li>
            <li><Link to="/admin/admindashboardeducator" target="_self">Educators Dashboard</Link></li>
            <li><Link to=""target="_self" className="active">Educator Create Account</Link></li>
            <li><Link to="/admin/educationprofile" target="_self">Educator Profile</Link></li>
            </ul>
        </div>
        <div className="right-pannel">
        <form>
          <h2><img src={require('../assets/img/logo.png')} alt="Logo" />Educator Create an Account</h2>
          <div className="row">
            <div className="col-md-6">
                <div className="form-group formelement-block">
                    <label htmlFor="exampleInputEmail1">Institution Name</label>
                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Institution Names"/>
                  
                  </div>
            </div>

            <div className="col-md-6">
                <div className="form-group formelement-block">
                    <label htmlFor="exampleInputEmail1">Address</label>
                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Address"/>
                  
                  </div>
            </div>
            <div className="col-md-6">
                <div className="form-group formelement-block">
                    <label htmlFor="exampleInputEmail1">Website</label>
                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Website"/>
                  
                  </div>
            </div>
            <div className="col-md-6">
                <div className="form-group formelement-block">
                    <label htmlFor="exampleInputEmail1">Primary Contact w/(all contact information)</label>
                    <input type="tel" className="form-control" id="exampleInputEmail1" placeholder="Primary Contact"/>
                    <p className="error-msg">*please enter phone number</p>
                  </div>
            </div>
            <div className="col-md-6">
                <div className="form-group formelement-block">
                    <label htmlFor="exampleInputEmail1">Secondary Contact w/(all contact information)</label>
                    <input type="tel" className="form-control" id="exampleInputEmail1" placeholder="Secondary Contact"/>
                    <p className="error-msg">*please enter phone number</p>
                  </div>
            </div>
            <div className="col-md-6">
                <div className="form-group formelement-block">
                    <label htmlFor="exampleInputEmail1">School Profile</label>
                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="School Profile"/>
                
              </div>
            </div>
            <div className="col-md-6">
                <div className="form-group formelement-block">
                    <label htmlFor="exampleInputEmail1">Veteran Services on campus</label>
                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Veteran Services on campus"/>
                  
              </div>
            </div>
            <div className="col-md-6">
                <div className="form-group formelement-block">
                    <label htmlFor="exampleInputEmail1">Assessment Tests given</label>
                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Assessment Tests given"/>
                  
              </div>
            </div>
            <div className="col-md-6">
                <div className="form-group formelement-block">
                    <label htmlFor="exampleInputEmail1">Degrees offered</label>
                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Degree levels offered"/>
                  
              </div>
            </div>
          
            <div className="col-md-12" style={{width:"100%"}}>
            <button type="submit" className="btn btn-primary sign-btn">Submit</button>
            
          </div>
          </div>
        </form>
        </div>
    </div>
</div>
    </>
)
}