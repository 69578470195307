import React, { useState, useEffect } from 'react';
import Sidebar from "../../component/Sidebar/Sidebar";
import EducatorNavbar from "../../component/Navbars/EducatorNavbar";
import educatorroutes from "./educatorroutes.js";
import { viewEducatorUserData } from '../../service/UserService.js';
import Cookies from 'universal-cookie';
import { useNavigate, Link } from 'react-router-dom';

function EducatorProfile() {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const [userInfo, setUser] = useState({
        userName: '',
        institutionName: '',
        website: '',
        schoolProfile: ''
    });
    useEffect(() => {
        var Key = localStorage.getItem('USER_KEY');
        if (Key != undefined) {
            var name = cookies.get('userName')
            viewEducatorUserData('?userName=' + name).then((response) => {
                console.log("response", response);
                if (response.status === 200) {
                    setUser({
                        ...userInfo, institutionName: response.data.institutionName,
                        website: response.data.website,
                        schoolProfile: response.data.schoolProfile
                    });
                    document.getElementById("profile").classList.add("active");
                }
            })
        } else {
            navigate('../login');
        }
    }, []);
    return (
        <>
            <div className="wrapper">
                <Sidebar color={"black"} image={""} routes={educatorroutes} />
                <div className="main-panel" ref={null}>
                    <EducatorNavbar />
                    <div className="container-bg">
                        <h2 className="wel-txt">Educator Profile</h2>
                        <br/>
                        <div className="rank-card-block">
                            <div>
                                <div className="rank-block">
                                    <img src={require('../../assets/img/unv1_logo.PNG')} alt='logo' />
                                </div>
                            </div>
                
                            <div>
                                <h6>Institution Name: {userInfo.institutionName}</h6>
                                <h6>Website Name: {userInfo.website}</h6>
                                <h6>School Profile: {userInfo.schoolProfile}</h6>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
            
        </>
        
    )
}
export default EducatorProfile;
