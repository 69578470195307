
import React, { useState, useEffect } from 'react';
import Sidebar from "../component/Sidebar/Sidebar";
import AdminNavbar from "../component/Navbars/AdminNavbar";
import routes from "../routes.js";
import {viewUserData} from '../service/UserService.js';
import Cookies from 'universal-cookie';
import {useNavigate, Link} from 'react-router-dom';
 
function Profile(){
    const navigate = useNavigate();
    const cookies = new Cookies();
          const [userInfo, setUser] = useState({
                  userName: '',
                  password: '',
                  rankIcon:'',
                  address1: '',
                  address2:'',
                  rankId:'',
                  rankCode:'',
                  rankName:''
        }); 
        useEffect(() => {
          var Key = localStorage.getItem('USER_KEY');
          if (Key != undefined){
              var name   = cookies.get('userName')
              viewUserData('?userName=' + name).then((response)=>{
                console.log("response",response);
                if(response.status===200){

                    setUser({...userInfo, firstName : response.data.firstName, 
                      lastName: response.data.lastName, rankIcon:response.data.rankIcon,
                      address1: response.data.address1, address2: response.data.address2,
                      rankId:response.data.rankId,
                      rankCode:response.data.rankCode,
                      rankName:response.data.rankName});
                      document.getElementById("profile").classList.add("active");
                  }
                  })
          }else {
              navigate('../login');
          }
        },[]);
return(
      <>
          <div className="wrapper">
                <Sidebar color={"black"} image={""} routes={routes} />
                <div className="main-panel" ref={null}>
                    <AdminNavbar />
                    <div className="container-bg">
                        <h2 className="wel-txt">Profile</h2>
                        <div className="rank-card-block">
                            <div>
                                  <div className="rank-block">
                                  
                                  {userInfo.rankIcon == null ? (
                                        <img src={require('../assets/img/dummy-img.png')} alt='logo' />
                                        ) : (
                                        <img src={"data:image/png;base64," + userInfo.rankIcon} alt="Logo" />
                                    )}
                                  </div>
                                  
                            </div>
                            <div>
                                <h4>{userInfo.firstName + " " + userInfo.lastName}</h4>
                                <h6>{userInfo.address1 + " " + userInfo.address2}</h6>
                            </div>
                           
                        </div>
                        <p className='rank-code'>{userInfo.rankCode + " " + userInfo.rankName}</p>
                        <br />
                        <br />                        
                    </div>
                </div>
          </div>
      </>
)
}
export default Profile;
