import React from 'react'
import {useNavigate, Link} from 'react-router-dom';
export default function Admindashboardveterans() {


return(
    <>
<div className="wrapper">
    <div className="signup-bg" style={{display:"flex",padding:"0"}}>
        <div className="left-pannel">
        <ul style={{margin:"0",padding:"0",listStyleType: "none"}}>
          <li><Link to="/admin/creataccount">Admin Create Account</Link></li>
          <li><Link to="/admin/admindashboard" target="_self">Admin Dashboard</Link></li>
          <li><Link to="" className="active" target="_self">Veterans Dashboard</Link></li>
          <li><Link to="/admin/admindashboardeducator" target="_self">Educators Dashboard</Link></li>
          <li><Link to="/admin/educatorcreationaccount"target="_self">Educator Create Account</Link></li>
          <li><Link to="/admin/educationprofile" target="_self">Educator Profile</Link></li>
        </ul>
        </div>
        <div className="right-pannel">
          <form>
            <h2><img src={require('../assets/img/logo.png')} alt="Logo" />Veterans Dashboard</h2>
            <div className="row">


              <div className="col-md-6">
                  <div className="form-group formelement-block">
                      <label htmlFor="exampleInputEmail1">Submitted for approval</label>
                  <select className="form-control" id="veteransList">
                    <option>Option One</option>
                    <option>Option Two</option>
                    <option>Option Three</option>
                    
                  </select>
                    
                    </div>
              </div>
              <div className="col-md-6">
                  <div className="form-group formelement-block">
                      <label htmlFor="exampleInputEmail1">Approved to view offers</label>
                      <select className="form-control" id="viewoffers">
                        <option>Option One</option>
                        <option>Option Two</option>
                        <option>Option Three</option>
                    </select>
                    
                    </div>
              </div>
              <div className="col-md-6">
                  <div className="form-group formelement-block">
                      <label htmlFor="exampleInputEmail1">Applied to school</label>
                      <select className="form-control" id="veteransList">
                        <option>Option One</option>
                        <option>Option Two</option>
                        <option>Option Three</option>
                    </select>
                      
                    </div>
              </div>
              <div className="col-md-6">
                  <div className="form-group formelement-block">
                      <label htmlFor="exampleInputEmail1">Accepted by school</label>
                      <select className="form-control" id="veteransList">
                          <option>Option One</option>
                          <option>Option Two</option>
                          <option>Option Three</option>
                    </select>
                    
                    </div>
              </div>   
              <div className="col-md-12" style={{width:"100%"}}>
              <button type="submit" className="btn btn-primary sign-btn">Submit</button>
              
            </div>
            </div>
          </form>
        </div>
    </div>
</div>
    
    </>
)
}