
const appEducatorRoutes = [
  {
    path: "/profile",
    name: "Profile",
    icon: "nc-icon nc-circle-09",
    layout: "/educator",
    id:"educatorprofile"
  },
  {
    path: "/details",
    name: "Educator Information",
    icon: "nc-icon nc-circle-09",
    layout: "/educator",
    id:"educatordetails"
  },
  {
    path: "/Changepassword",
    name: "Change Password",
    icon: "nc-icon nc-circle-09",
    layout: "/educator",
    id:"educatorpwdchange"
  },
  {
    path: "/EducatorDashboard",
    name: "Educator Dashboard",
    icon: "nc-icon nc-circle-09",
    layout: "/educator",
    id:"educatordashboard"
  }
];

export default appEducatorRoutes;
