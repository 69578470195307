import React from 'react'
import {useNavigate, Link} from 'react-router-dom';
export default function Educatorprofile() {


return(
    <>
<div className="wrapper">
    <div className="signup-bg" style={{display:"flex",padding:"0"}}>
        <div className="left-pannel" style={{width:"30%"}}>
            <ul style={{margin:"0",padding:"0",listStyleType:"none"}}>
            <li><Link to="/admin/creataccount">Admin Create Account</Link></li>
            <li><Link to="/admin/admindashboard" target="_self">Admin Dashboard</Link></li>
            <li><Link to="/admin/admindashboardveterans" target="_self">Veterans Dashboard</Link></li>
            <li><Link to="/admin/admindashboardeducator" target="_self">Educators Dashboard</Link></li>
            <li><Link to="/admin/educatorcreationaccount"target="_self">Educator Create Account</Link></li>
            <li><Link to="" target="_self" className="active">Educator Profile</Link></li>
            </ul>
        </div>
        <div className="right-pannel">
          <form>
            <h2><img src={require('../assets/img/logo.png')} alt="Logo" />Educator Profile</h2>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Bio info</label>
                  <select className="form-control" id="veteransList">
                    <option>Veterans to Review List</option>
                    <option>Veterans Approved List</option>
                    <option>Veterans Accepted List</option>
                    
                  </select>
                </div>
              </div>
                <div className="col-md-12" style={{width:"100%",marginTop: "50px;"}}>
                <button type="submit" className="btn btn-primary sign-btn">Submit</button>
                </div>
            </div>
          </form>
        </div>
    </div>
</div>
    
    </>
)
}