
import React from 'react'
import { useState, useEffect } from 'react';
import {useNavigate, Link} from 'react-router-dom';
import {forgotPassword} from '../service/UserService.js';
import Cookies from 'universal-cookie';

export default function Forgot(){
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    emailId: '',
     }); 
    const [error, setError] = useState({
      emailId: '',
    })
    var validValue =true; 
  const handleSubmit=async (e)=>{
    e.preventDefault();
    var  value= document.getElementById("exampleInputEmail1").value;
    await validateForm("emailId",value)
    if (!validValue ) { return false;  } 
    forgotPassword('?username=' + values.emailId).then((response)=>{
      console.log("response",response);
      document.getElementById("show").style.display="block";
   }).catch((err)=>{
    if(err && err.response){

    switch(err.response.status){
        case 100:
            console.log("401 status");
            alert(err.response.data);
            break;
        default:
          var  name= "emailId";
          setError(prev => {
            const stateObj = { ...prev, [name]: "" };

            stateObj[name] = err.response.data;

            return stateObj;
                  })
        
     }
  }
}) 
   
  }
  const validateEmail = (email) => {
    const emailPattern =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return emailPattern.test(email);
  }; 

  const onInputChange   = (e) => {

    const { name, value } = e.target;
    setValues(prev => ({
      ...prev,
      [name]: value
    }));
    validateInput(e);
  }

  const validateInput = e => {
    let { name, value } = e.target;
    validateForm(name, value);
  } 
  const validateForm  = (name, value) => {
    var result = true ;
      setError(prev => {
        const stateObj = { ...prev, [name]: "" };
        switch (name) {
   
          case "emailId":
            if (!value) {
              stateObj[name] = "Please enter valid Email Address";
              validValue =false;
            } else{
                if (!validateEmail(value))
              {
                validValue =false;
                stateObj[name] = "Please enter valid Email Address";
              }
            }

            break;

          default:
            break;
        }
        return stateObj;

   });
  } 
    return(
        <>
    <div className="wrapper pass-reset">
      <div className="signup-bg forgot-radious">
        <form onSubmit={handleSubmit}>
          <h2 style={{fontSize:"28px"}}> <img  style={{width:"70px"}} src={require("../assets/img/logo.png")} alt="Logo" />Forgot Password</h2>
          <div className="row">
            <div className="col-md-12">
                <div className="form-group formelement-block">
                    <label htmlFor="exampleInputEmail1">Email</label>
                    <input type="Text" className="form-control" id="exampleInputEmail1" onChange={onInputChange} onBlur={validateInput} value={values.emailId} placeholder="Email" name='emailId'/>
                    {error.emailId && <span className='err'>{error.emailId}</span>}  
                </div>
            </div>
          </div>
          <p><button type="submit" className="btn submit-btn">Submit</button></p>
        </form>
      </div>
    </div>
    <div className="" id="show" style={{display:"done"}}><div>Succesfully Submited. Please check your email. </div><a href="LoginPage">OK</a></div>
    </>
)
}

 
