import axios from 'axios';

const getToken=()=>{
    return localStorage.getItem('USER_KEY');
}
export const updateUserData=(value)=>{
    console.log(value);
    console.log(getToken());
    return axios({
        method:'POST',
        url:`${process.env.hostUrl||'https://api.actservice.org'}/api/update`,
        headers:{
            'Authorization':'Bearer '+getToken(),
            'accept': 'application/json',
            'content-type': 'application/json'
        },
        data: value
    })
}

export const viewUserData=(userName)=>{
    console.log(userName);
    console.log(getToken());
    return axios({

        method:'GET',
        url:`${process.env.hostUrl||'https://api.actservice.org'}/api/userInfo/${userName}`,
        headers:{
            'Authorization':'Bearer '+getToken(),
            'accept': 'application/json',
            'content-type': 'application/json'
        }
    })
}

export const viewTrainingData=(userName)=>{
    console.log(userName);
    console.log(getToken());
    return axios({
        method:'GET',
        url:`${process.env.hostUrl||'https://api.actservice.org'}/api/user-trainings/${userName}`,
        headers:{
            'Authorization':'Bearer '+getToken(),
            'accept': 'application/json',
            'content-type': 'application/json'
        }
    })
}

export const updateTrainingData=(value)=>{

    return axios({
        method:'POST',
        url:`${process.env.hostUrl||'https://api.actservice.org'}/api/update-training`,
        headers:{
            'Authorization':'Bearer '+getToken(),
            'accept': 'application/json',
            'content-type': 'application/json'
        },
        data: value
    })
    
}

export const fileUpload=(value)=>{
    console.log('File Upload called'+value);
    
    return axios({
        method:'POST',
        url:`${process.env.hostUrl||'https://api.actservice.org'}/api/s3/file/upload/`,
        headers:{
            'Authorization':'Bearer '+getToken(),
            'accept': 'application/json',
            'content-type': `multipart/form-data; boundary=${value._boundary}`
        },
        data: value               
    })    
}

export const checkIfUserUploadedFile=(userName, fileType)=>{
    console.log(userName);
    console.log(getToken());
    return axios({
        method:'GET',
        url:`${process.env.hostUrl||'https://api.actservice.org'}/api/userUploadedFile/${userName}${fileType}`,
        headers:{
            'Authorization':'Bearer '+getToken(),
            'accept': 'application/json',
            'content-type': 'application/json'
        }
    })
}

export const resumeUpload=(value)=>{
    console.log('Resume Upload called'+value);
    
    return axios({
        method:'POST',
        url:`${process.env.hostUrl||'https://api.actservice.org'}/api/s3/resume/upload/`,
        headers:{
            'Authorization':'Bearer '+getToken(),
            'accept': 'application/json',
            'content-type': `multipart/form-data; boundary=${value._boundary}`
        },
        data: value               
    })    
}

export const fileDownload=(userName, fileType)=>{
    console.log(userName);
    console.log(getToken());
    return axios({
        method:'GET',
        responseType: 'blob',
        url:`${process.env.hostUrl||'https://api.actservice.org'}/api/s3/file/download/${userName}${fileType}`,
        headers:{
            'Authorization':'Bearer '+getToken(),
            'accept': 'application/octet-stream',
            'content-type': 'application/octet-stream'
        },
        timeout: 30000
    })
}

export const signup=(value)=>{
    console.log('Sign Up called');
    return axios({
        method:'POST',
        url:`${process.env.hostUrl||'https://api.actservice.org'}/api/signup`,
        headers:{
            'accept': 'application/json',
            'content-type': 'application/json'
        },
        data: value
    })
}

export const educatorsignup=(value)=>{
    console.log('Educator Sign Up called');
    return axios({
        method:'POST',
        url:`${process.env.hostUrl||'https://api.actservice.org'}/api/educator/signup`,
        headers:{
            'accept': 'application/json',
            'content-type': 'application/json'
        },
        data: value
    })
}

export const passwordReset=(value)=>{
    return axios({
        method:'POST',
        url:`${process.env.hostUrl||'https://api.actservice.org'}/api/passwordReset`,
        headers:{
            'accept': 'application/json',
            'content-type': 'application/json'
        },
        data: value
    })
}


export const states=()=>{
    return axios({
        method:'GET',
        url:`${process.env.hostUrl||'https://api.actservice.org'}/api/states`,
        headers:{
            'accept': 'application/json',
            'content-type': 'application/json'
        }
    })
}


export const forgotPassword=(username)=>{
    console.log(username);
    console.log(getToken());
    return axios({
        method:'GET',
        url:`${process.env.hostUrl||'https://api.actservice.org'}/api/forgotPassword/${username}`,
        headers:{
            // 'Authorization':'Bearer '+getToken(),
            'accept': 'application/json',
            'content-type': 'application/json'
        }
    })
}

export const cities=(stateId)=>{
    return axios({

        method:'GET',
        url:`${process.env.hostUrl||'https://api.actservice.org'}/api/cities/${stateId}`,
        headers:{
            'accept': 'application/json',
            'content-type': 'application/json'
        }
    })
}

export const branch=()=>{
    return axios({

        method:'GET',
        url:`${process.env.hostUrl||'https://api.actservice.org'}/api/branch`,
        headers:{
            'accept': 'application/json',
            'content-type': 'application/json'
        }
    })
}

export const rank=(branchId)=>{
    return axios({

        method:'GET',
        url:`${process.env.hostUrl||'https://api.actservice.org'}/api/rank/${branchId}`,
        headers:{
            'accept': 'application/json',
            'content-type': 'application/json'
        }
    })
}

export const getAllUploadStatuses=()=>{
    console.log(getToken());
    return axios({
        method:'GET',
        url:`${process.env.hostUrl||'https://api.actservice.org'}/api/getAllUploadStatuses`,
        headers:{
            'Authorization':'Bearer '+getToken(),
            'accept': 'application/json',
            'content-type': 'application/json'
        }
    })
}

export const getAllVeteransByStatus=(status)=>{
    return axios({
        method:'GET',
        url:`${process.env.hostUrl||'https://api.actservice.org'}/api/getAllVeteransByStatus/${status}`,
        headers:{
            'Authorization':'Bearer '+getToken(),
            'accept': 'application/json',
            'content-type': 'application/json'
        }
    })
}

export const viewEducatorUserData=(userName)=>{
    console.log(userName);
    console.log(getToken());
    return axios({

        method:'GET',
        url:`${process.env.hostUrl||'https://api.actservice.org'}/api/educator/userInfo/${userName}`,
        headers:{
            'Authorization':'Bearer '+getToken(),
            'accept': 'application/json',
            'content-type': 'application/json'
        }
    })
}

export const updateEducatorUserData=(value)=>{
    console.log(value);
    console.log(getToken());
    return axios({
        method:'POST',
        url:`${process.env.hostUrl||'https://api.actservice.org'}/api/educator/update`,
        headers:{
            'Authorization':'Bearer '+getToken(),
            'accept': 'application/json',
            'content-type': 'application/json'
        },
        data: value
    })
}