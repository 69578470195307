import React, { useState, useEffect } from "react";
import EducatorNavbar from "../../component/Navbars/EducatorNavbar.js";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { fileDownload } from "../../service/UserService.js";

import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  HeaderCell,
  Cell,
} from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";

const handleResumeClick = (param) => (e) => { 
  //console.log('Resume Link clicked!');
  //console.log('Event', e);
  //console.log('Parameter', param);

  fileDownload('?userName=' + param , '&fileType=RESUME').then((response) => {
    console.log("response", response);
    if (response.status === 200 && response.data) {
        //show the already uploaded message
        document.getElementById("user-file-download").style.display = "block";
        console.log("Success");

        //Create a Blob from the PDF Stream
        const file = new Blob([response.data],{type: 'application/msword'});
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
    }
  }).catch((err) => {
      if (err && err.response) {
        document.getElementById("user-file-download-error").style.display = "block";        
          switch (err.response.status) {
              case 401:
                  console.log("401 status");
                  break;
              default:
                console.log('Something Wrong! Please Try Again');
          }
      }
  });

}

const handleDD214Click = (param) => (e) => { 
  console.log('DD214 Link clicked!');
  console.log('Event', e);
  console.log('Parameter', param);

  fileDownload('?userName=' + param , '&fileType=DD214').then((response) => {
    console.log("response", response);
    if (response.status === 200 && response.data) {
        //show the already uploaded message
        document.getElementById("user-file-download").style.display = "block";
        console.log("Success");

        //Create a Blob from the PDF Stream
        const file = new Blob([response.data],{type: 'application/pdf'});
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
    }
  }).catch((err) => {
      if (err && err.response) {
        document.getElementById("user-file-download-error").style.display = "block";
          switch (err.response.status) {
              case 401:
                  console.log("401 status");
                  break;
              default:
                console.log('Something Wrong! Please Try Again');
          }
      }
  });

}



function EducatorVeteranDashboardView() {
  const location = useLocation();

  let nodes = [
    {
      id: "0",
      firstName: "Nanda",
      lastName: "Kishore",
      email: "nanda.se@gmail.com",
      phoneNumber: "123-234-5678",
      address: "Kukatpally",
      city: "Hyderabad",
      state: "TG",
      zipCode: 12345,
    },
    {
      id: "1",
      firstName: "Nanda1",
      lastName: "Kishore1",
      email: "nanda.se1@gmail.com",
      phoneNumber: "123-234-5678",
      address: "Lingampalli",
      city: "Hyderabad",
      state: "TG",
      zipCode: 12345,
    },
  ];

  nodes = location.state;

  const key = "Composed Table";

  const data = { nodes };

  const theme = useTheme(getTheme());

  return (
    <>
      <div className="wrapper" style={{width: "1200px"}}>

        <div className="main-panel" ref={null}>
          <EducatorNavbar />
          <div className="signup-bg" style={{ display: "flex", padding: "0" }}>
            <div className="right-pannel">
              <h2>
                <img src={require("../../assets/img/logo.png")} alt="Logo" />
                Educator's - Veteran Dashboard
              </h2>

              <div id="user-file-download" style={{ display: "none" }}>
                <center><div>The user has Succesfully dowloaded Document!! </div> </center>
              </div>

              <div id="user-file-download-error" style={{ display: "none" }}>
                <center><div>The user has Failed to dowload Document. Please try after some time!! </div> </center>
              </div>

              <div
                className="row"
                style={{ width: "100%", paddingBottom: "20px" }}
              >
                <Table data={data} theme={theme}>
                  {(tableList) => (
                    <>                    
                      <Header>
                        <HeaderRow>
                          <HeaderCell>First Name</HeaderCell>
                          <HeaderCell>Last Name</HeaderCell>
                          <HeaderCell>Email</HeaderCell>
                          <HeaderCell>Phone</HeaderCell>
                          <HeaderCell>State</HeaderCell>
                          <HeaderCell>Status</HeaderCell>
                        
                          <HeaderCell>Resume</HeaderCell>
                          <HeaderCell>DD214</HeaderCell>                        
                       
                        </HeaderRow>
                      </Header>
                    
                      {tableList.length !== 0 ? (
                        <Body>
                        {tableList.map((item) => (
                          <Row key={item.id} item={item}>
                            <Cell>{item.firstName}</Cell>
                            <Cell>{item.lastName}</Cell>
                            <Cell>{item.emailId}</Cell>
                            <Cell>{item.phoneNumber}</Cell>
                            <Cell>{item.state}</Cell>
                            <Cell>{item.status}</Cell>

                            {item.status === "SUBMITTED" && 
                              <>
                                <Cell><Link to="#" onClick={handleResumeClick(item.emailId)} target="_blank">Resume</Link></Cell>
                                <Cell><Link to="#" onClick={handleDD214Click(item.emailId)} target="_blank">DD214</Link></Cell>
                              </>
                              } else { item.status === "NEW" && 
                                <>
                                <Cell>Not Uploaded</Cell>
                                <Cell>Not Uploaded</Cell>
                                </>
                              }
                            
                          </Row>
                        ))}
                      </Body>
        ) : (
          <Body><Row><div className="row" style={{textAlign: "center", verticalAlign: "middle", padding: "5px"}}> 
          <Cell>No Records Found</Cell></div></Row></Body>
        )} 

                    </>
                  )}
                </Table>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EducatorVeteranDashboardView;
