
import React, { useEffect, useState, useRef } from 'react'
import Sidebar from "../component/Sidebar/Sidebar";
import AdminNavbar from "../component/Navbars/AdminNavbar";
import routes from "../routes.js";
import { useNavigate, Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { resumeUpload, checkIfUserUploadedFile } from '../service/UserService.js';


const getToken = () => {
    return localStorage.getItem('USER_KEY');
}

function ResumeUpload(props) {

    const navigate = useNavigate();
    const cookies = new Cookies();
    const [values, setValues] = useState({
        id: 0,
        userName: '',
        bucketName: ''
    });

    const [show, toggleShow] = React.useState(true);


    useEffect(() => {
        var Key = localStorage.getItem('USER_KEY');
        if (Key != undefined) {

        } else {
            window.location.href = "../login";
        }
        var name = cookies.get('userName')

        checkIfUserUploadedFile('?userName=' + name , '&fileType=RESUME').then((response) => {
            console.log("response", response);
            if (response.status === 200 && response.data) {
                //show the already uploaded message
                document.getElementById("user-file-upload").style.display = "block";
            }
        }).catch((err) => {
            if (err && err.response) {
                switch (err.response.status) {
                    case 401:
                        console.log("401 status");
                        break;
                    default:
                }
            }
        });

        setValues(prev => ({
            ...prev,
            userName: name
        }));

    }, [])

    // a local state to store the currently selected file.
    const [selectedFile, setSelectedFile] = React.useState(null);

    const ref = useRef();

    const reset = () => {
        ref.current.value = "";
    };

    const handleSubmit = async (event) => {
        event.preventDefault()

        /*if (!selectedFile) {
            alert("Please select a valid doc/docx file to upload.");
            return;
        }*/

        const formData = new FormData();
        formData.append("userName", values.userName);
        formData.append("file", selectedFile);
        formData.append("fileType", "RESUME");
        console.log(formData);

        resumeUpload(formData).then((response) => {
            console.log("response", response);
            if (response.status === 200) {
                document.getElementById("show").style.display = "block";
                event.target.value = null;
            }
        }).catch((err) => {
            if (err && err.response) {
                switch (err.response.status) {
                    case 401:
                        console.log("401 status");
                        break;
                    default:
                        props.loginFailure('Something Wrong!Please Try Again');
                }
            }
            else {
                props.loginFailure('Something Wrong!Please Try Again');
            }
        });

    }

    const handleFileSelect = (event) => { 
        const uploadedFile = event.target.files[0];

        // Validate the file type
        /*if (!['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(uploadedFile.type)) {
            alert('Invalid file type. Please upload a DOC file.');
            return;
        }*/
    
        // Validate the file size
        /*if (uploadedFile.size > 1024 * 1024 * 2) {
            alert('File size too large. Please upload a file smaller than 2MB.');
            return;
        }*/
     
        setSelectedFile(uploadedFile);
    }

    const onClickOk = e => {
        document.getElementById("show").style.display = "none";
        reset();
    }

    return (
        <>
            <div className="wrapper">
                <Sidebar color={"black"} image={""} routes={routes} />
                <div className="main-panel" ref={null}>
                    <AdminNavbar />
                    <div className="container-bg">
                        <h2 className="wel-txt">Resume Upload</h2>
                        <br />
                        <br />
                        <br />
                        <form onSubmit={handleSubmit}>
                            <input type="file" onChange={handleFileSelect} ref={ref} />
                            <br />
                            <center><input type="submit" className="btn btn-primary update-btn" value="Upload File" /></center>
                        </form>
                        <br />
                        <div id="user-file-upload" style={{ display: "none" }}>
                            <center><div>The user has already uploaded Resume Document!! </div> </center>
                        </div>
                        <br />

                        <div>If you want to upload your latest resume. Please upload in doc/docx format only.</div>

                        <br />
                        <br />
                    </div>
                    <div id="show" style={{ display: "done" }}>
                        <div>Resume Uploaded Succesfully!! </div>
                        <a onClick={onClickOk}>OK</a>
                    </div>

                </div>
            </div>
        </>

    )
}
export default ResumeUpload;