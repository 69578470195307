
const appAdminRoutes = [
  {
    path: "/adminprofile",
    name: "Profile",
    icon: "nc-icon nc-circle-09",
    layout: "/adminuser",
    id:"adminprofile"
  },
  {
    path: "/Changepassword",
    name: "Change Password",
    icon: "nc-icon nc-circle-09",
    layout: "/admin",
    id:"adminpwdchange"
  },
  {
    path: "/AdmDashboard",
    name: "Admin Dashboard",
    icon: "nc-icon nc-circle-09",
    layout: "/admin",
    id:"admindashboard"
  }
];

export default appAdminRoutes;
