import React from 'react'
import {useNavigate, Link} from 'react-router-dom';
export default function Creataccount() {


return(
    <>
<div className="wrapper">
    <div className="signup-bg" style={{display:"flex",padding:"0"}}>
        <div className="left-pannel">
            <ul style={{margin:"0", padding:"0", listStyleType: "none"}}>
              <li><Link to="" className="active">Admin Create Account</Link></li>
              <li><Link to="/admin/admindashboard" target="_self">Admin Dashboard</Link></li>
              <li><Link to="/admin/admindashboardveterans" target="_self">Veterans Dashboard</Link></li>
              <li><Link to="/admin/admindashboardeducator" target="_self">Educators Dashboard</Link></li>
              <li><Link to="/admin/educatorcreationaccount"target="_self">Educator Create Account</Link></li>
              <li><Link to="/admin/educationprofile" target="_self">Educator Profile</Link></li>
            </ul>
        </div>
        <div className="right-pannel">
          <form>
            <h2><img src={require('../assets/img/logo.png')} alt="Logo" />Admin Create an Account</h2>
            <div className="row">
              <div className="col-md-6">
                  <div className="form-group formelement-block">
                      <label htmlFor="exampleInputEmail1">Name</label>
                      <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Name"/>
                      <p className="error-msg">*please enter Name</p>
                    </div>
              </div>

              <div className="col-md-6">
                  <div className="form-group formelement-block">
                      <label htmlFor="exampleInputEmail1">Phone</label>
                      <input type="tel" className="form-control" id="exampleInputEmail1" placeholder="Phone"/>
                      <p className="error-msg">*please enter phone number</p>
                    </div>
              </div>
              <div className="col-md-6">
                  <div className="form-group formelement-block">
                      <label htmlFor="exampleInputEmail1">Email</label>
                      <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Email"/>
                      <p className="error-msg">*please enter valid email address</p>
                </div>
              </div>
              <div className="col-md-6">
                  <div className="form-group formelement-block">
                      <label htmlFor="exampleInputEmail1">Title</label>
                      <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Title"/>
                    
                </div>
              </div>
              <div className="col-md-6">
                  <div className="form-group formelement-block">
                      <label htmlFor="exampleInputEmail1">New Password</label>
                      <input type="password" className="form-control" id="exampleInputEmail1" placeholder="New Password"/>
                </div>
              </div>
                  <div className="col-md-6">
                  <div className="form-group formelement-block">
                      <label htmlFor="exampleInputEmail1">Confirm Password</label>
                      <input type="password" className="form-control" id="exampleInputEmail1" placeholder="Confirm Password"/>
                    
                </div>
              </div>
              <div className="col-md-12">
                  <div className="form-group formelement-block">
                      <label htmlFor="exampleInputEmail1">Office Location</label>
                      <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Office Location"/>
                  </div>
              </div>
              <div className="col-md-12" style={{width:"100%"}}>
              <button type="submit" className="btn btn-primary sign-btn">Submit</button>
              
            </div>
            </div>
          </form>
        </div>
    </div>
</div>
    </>
)

}

