import React, { useState, useEffect } from "react";
import Sidebar from "../../component/Sidebar/Sidebar";
import AdminNavbar from "../../component/Navbars/AdminNavbar";
import adminroutes from "../adminroutes.js";
import Cookies from "universal-cookie";
import { useLocation, useNavigate, Link } from "react-router-dom";

import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  HeaderCell,
  Cell,
} from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";

//import { nodes } from '../data';
//first_name, last_name, email, phone_number,address1,city, state, zip_code

function VeteranDashboardView() {
  const location = useLocation();

  let nodes = [
    {
      id: "0",
      firstName: "Nanda",
      lastName: "Kishore",
      email: "nanda.se@gmail.com",
      phoneNumber: "123-234-5678",
      address: "Kukatpally",
      city: "Hyderabad",
      state: "TG",
      zipCode: 12345,
    },
    {
      id: "1",
      firstName: "Nanda1",
      lastName: "Kishore1",
      email: "nanda.se1@gmail.com",
      phoneNumber: "123-234-5678",
      address: "Lingampalli",
      city: "Hyderabad",
      state: "TG",
      zipCode: 12345,
    },
  ];

  nodes = location.state;

  const key = "Composed Table";

  const data = { nodes };

  const theme = useTheme(getTheme());

  return (
    <>
      <div className="wrapper" style={{width: "1200px"}}>

        <div className="main-panel" ref={null}>
          <AdminNavbar />
          <div className="signup-bg" style={{ display: "flex", padding: "0" }}>
            <div className="right-pannel">
              <h2>
                <img src={require("../../assets/img/logo.png")} alt="Logo" />
                Veteran Dashboard
              </h2>

              <div
                className="row"
                style={{ width: "100%", paddingBottom: "20px" }}
              >
                <Table data={data} theme={theme}>
                  {(tableList) => (
                    <>
                    
                      <Header>
                        <HeaderRow>
                          <HeaderCell>First Name</HeaderCell>
                          <HeaderCell>Last Name</HeaderCell>
                          <HeaderCell>Email</HeaderCell>
                          <HeaderCell>Phone</HeaderCell>
                          <HeaderCell>Address</HeaderCell>
                          <HeaderCell>City</HeaderCell>
                          <HeaderCell>State</HeaderCell>
                          <HeaderCell>Zip Code</HeaderCell>
                          <HeaderCell>Status</HeaderCell>
                        </HeaderRow>
                      </Header>
                    
                      {tableList.length !== 0 ? (
                        <Body>
                        {tableList.map((item) => (
                          <Row key={item.id} item={item}>
                            <Cell>{item.firstName}</Cell>
                            <Cell>{item.lastName}</Cell>
                            <Cell>{item.emailId}</Cell>
                            <Cell>{item.phoneNumber}</Cell>
                            <Cell>{item.address1}</Cell>
                            <Cell>{item.city}</Cell>
                            <Cell>{item.state}</Cell>
                            <Cell>{item.zipCode}</Cell>
                            <Cell>{item.status}</Cell>
                          </Row>
                        ))}
                      </Body>
        ) : (
          <Body><Row><div className="row" style={{textAlign: "center", verticalAlign: "middle", padding: "5px"}}> 
          No Records Found</div></Row></Body>
        )} 

                    </>
                  )}
                </Table>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VeteranDashboardView;
