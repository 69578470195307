import React from 'react'
import {useNavigate, Link} from 'react-router-dom';
export default function Admindashboardeducator() {

return(
    <>
<div className="wrapper">
      <div className="signup-bg" style={{display:"flex",padding:"0"}}>
          <div className="left-pannel">
          <ul style={{margin:"0",padding:"0",listStyleType:"none"}}>
            <li><Link to="/admin/admindashboard" target="_self">Admin Dashboard</Link></li>
            <li><Link to="/admin/creataccount" target="_self">Admin Create Account</Link></li>
            <li><Link to="/admin/admindashboardveterans" target="_self">Veterans Dashboard</Link></li>
            <li><Link to="" className="active" target="_self">Educators Dashboard</Link></li>
            <li><Link to="/admin/educatorcreationaccount"target="_self">Educator Create Account</Link></li>
            <li><Link to="/admin/educationprofile" target="_self">Educator Profile</Link></li>
          </ul>
          </div>

      <div className="right-pannel">
          <form>
            <h2><img src={require('../assets/img/logo.png')} alt="Logo" />Educators Dashboard</h2>
            <div className="row"> 
                  <div className="col-md-6">
                      <div className="form-group formelement-block">
                          <label htmlFor="exampleInputEmail1">New Veterans</label>
                          <select className="form-control" id="veteransList">
                            <option>New Veterans</option>
                            <option>Option Two</option>
                            <option>Option Three</option>
                          </select>
                      
                    </div>
                  </div>
                  <div className="col-md-6">
                      <div className="form-group formelement-block">
                          <label htmlFor="exampleInputEmail1">Approved to the ACTS System</label>
                          <select className="form-control" id="veteransList">
                            <option>Approved to the ACTS System</option>
                            <option>Option Two</option>
                            <option>Option Three</option>
                          </select>
                        
                    </div>
                  </div>
                  <div className="col-md-6">
                      <div className="form-group formelement-block">
                          <label htmlFor="exampleInputEmail1">Active offers</label>
                          <select className="form-control" id="veteransList">
                            <option>Active offers</option>
                            <option>Option Two</option>
                            <option>Option Three</option>
                          </select>
                        
                    </div>
                  </div>
                  <div className="col-md-6">
                      <div className="form-group formelement-block">
                          <label htmlFor="exampleInputEmail1">Accepted offers</label>
                          <select className="form-control" id="veteransList">
                            <option>Accepted offers</option>
                            <option>Option Two</option>
                            <option>Option Three</option>
                          </select>
                        
                    </div>
                  </div>
                  <div className="col-md-12" style={{width:"100%"}}>
                  <button type="submit" className="btn btn-primary sign-btn">Submit</button>
                  </div>
            </div>
          </form>
      </div>

      </div>
</div>
    </>
)
}