import react,{useState} from 'react';
import { connect } from 'react-redux';
import { authenticate, authFailure, authSuccess } from '../redux/authActions';
import '../assets/css/loginpage.css';
import {userLogin} from '../service/AuthenticationService.js';
import {Alert,Spinner} from 'react-bootstrap';
import {useNavigate, Link} from 'react-router-dom';
import React from 'react'
import Cookies from 'universal-cookie';

const LoginPage=({loading,error,...props})=>{
    const navigate = useNavigate();
    
    const cookies = new Cookies();
    const [values, setValues] = useState({
        userName: '',
        password: ''
        }); 
        const [inputError, setError] = useState({
          userName: '',
          password: ''
        })
        var validValue =true; 
    const handleSubmit=  async  (evt)=>{
        evt.preventDefault();
        props.authenticate();

        var  value= document.getElementById("loginEmail").value;
        await validateForm("userName",value)

        var  value= document.getElementById("password").value;
        await validateForm("password",value)


        if (!validValue ) { 
          props.loginFailure('');
          return false;  
        } 

        userLogin(values).then((response)=>{
          cookies.set('userName', values.userName, { path: '/' });
            console.log("response",response);
            if(response.status===200){
                props.setUser(response.data);
                if(response.data.userType === "ADMIN") {
                  navigate('/adminuser/AdminProfile');
                } else if(response.data.userType === "EDUCATOR") {
                  navigate('/educator/profile');
                } else if(response.data.userType !== "ADMIN" && response.data.userType !== "EDUCATOR") {
                  navigate('/admin/Profile');    
                }
            }
            else{           
               props.loginFailure('Something Wrong! Please Try Again');
            }
        }).catch((err)=>{

            if(err && err.response){

            switch(err.response.status){
                case 401:
                    console.log("401 status");
                    props.loginFailure("Authentication Failed. Bad Credentials");
                    break;
                default:
                    props.loginFailure('Something Wrong!Please Try Again');

            }

            }
            else{
                props.loginFailure('Something Wrong!Please Try Again');
            }
        });

    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(prev => ({
          ...prev,
          [name]: value
        }));
        validateInput(e);
    };
    const validateEmail = (email) => {
      
      const emailPattern =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return emailPattern.test(email);
    }; 
    const validateInput = e => {
      let { name, value } = e.target;
      validateForm(name, value);
    } 
    const validateForm  = (name, value) => {
      setError(prev => {
        const stateObj = { ...prev, [name]: "" };
        switch (name) {
          case "userName":
            if (!value) {
              stateObj[name] = "Please enter Email.";
              validValue =false;
            } else{
                if (!validateEmail(value))
              {
                validValue =false;
                stateObj[name] = "Please enter valid Email Address";
              }
            }
            break;
   
          case "password":
            if (!value) {
              stateObj[name] = "*please enter password.";
              validValue =false;
            } 
            break;
          default:
            break;
        }
return stateObj;

   });
  }

    return (
        <>
            <div className="wrapper" style={{display: "inherit"}}>
              <div className="row">
                <div className="col-md-7 pr0" style={{display: "flex"}}>
                  <div className="signinleft">
                    <div className="sign-left-headding"><img src={require('../assets/img/logo.png')} alt="Logo" className="logo" />
                      A credit to your service
                    </div>
                    <div className="dial-block">
                        <p>
                        If you're a Veteran in a mental health crisis and you're thinking about hurting
                        yourself or you know a Veteran who's considering this-get help right away. You're
                        not alone.</p>
                        <span className="dial-cta">DIAL 988 THEN PRESS1</span>
                      </div>
                      <div className="helping-txt">
                        Helping Veterans get the credit they deserve from their years of service!
                        Veterans Stand together 501(c)(3).
                      </div>
                  </div>

                </div>
                <div className="col-md-5 pl0">
                  <div className="signinright">
                    <h2 className="signin-headding">Sign in</h2>
                    <form onSubmit={handleSubmit} noValidate={false}>
                      <div className="form-group formelement-block">
                        <label htmlFor="exampleInputEmail1">Email address</label>
                        <input type="text" className="form-control" minLength={5}   id="loginEmail" value={values.userName} onChange={handleChange}  onBlur={validateInput} name="userName" placeholder="Email" required />
                        <p className="invalid-feedback">UserId is invalid</p>
                        {inputError.userName && <span className='err'>{inputError.userName}</span>}  
                      </div>

                      <div className="form-group formelement-block">
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <input type="password" className="form-control"  placeholder="Password" id="password" minLength={3} value={values.password} onChange={handleChange}  onBlur={validateInput} name="password" />
                        {inputError.password && <span className='err'>{inputError.password}</span>} 
                      </div>
                      <p className="forgot-txt"><Link to = "/Forgot">Forgot Your Password</Link></p>

                      <button type="submit" className="btn btn-primary sign-btn">Sign in
                      {loading && ( <Spinner as="span" animation="border" size="sm"  role="status" aria-hidden="true" /> )}
                      </button>
                      <p>Don't have an account? <Link  to="/Signup">Sign up</Link></p>                      
                      <p>If you are an Educator <Link  to="EducatorSignup">Educator Sign up</Link></p>
                    </form>
                    { error && <Alert style={{marginTop:'20px'}} variant="danger">{error}</Alert>}
                  </div>
                </div>
              </div>
            </div>
        </>
    )
}

const mapStateToProps=({auth})=>{
    console.log("state ",auth)
    return {
        loading:auth.loading,
        error:auth.error
}}

const mapDispatchToProps=(dispatch)=>{
    return {
        authenticate :()=> dispatch(authenticate()),
        setUser:(data)=> dispatch(authSuccess(data)),
        loginFailure:(message)=>dispatch(authFailure(message))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(LoginPage);
