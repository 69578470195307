import React, { useState, useEffect } from 'react';
import Sidebar from "../component/Sidebar/Sidebar";
import AdminNavbar from "../component/Navbars/AdminNavbar";
import adminroutes from "./adminroutes.js";
import { viewUserData } from '../service/UserService.js';
import Cookies from 'universal-cookie';
import { useNavigate, Link } from 'react-router-dom';

function AdminProfile() {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const [userInfo, setUser] = useState({
        userName: '',
        password: '',
        rankIcon: '',
        rankId: '',
        rankCode: '',
        rankName: '',
        title: ''
    });
    useEffect(() => {
        var Key = localStorage.getItem('USER_KEY');
        if (Key != undefined) {
            var name = cookies.get('userName')
            viewUserData('?userName=' + name).then((response) => {
                console.log("response", response);
                if (response.status === 200) {

                    setUser({
                        ...userInfo, firstName: response.data.firstName,
                        lastName: response.data.lastName,
                        rankIcon: response.data.rankIcon,
                        rankId: response.data.rankId,
                        rankCode: response.data.rankCode,
                        rankName: response.data.rankName,
                        title: response.data.title
                    });
                    document.getElementById("profile").classList.add("active");
                }
            })
        } else {
            navigate('../login');
        }
    }, []);
    return (
        <>
            <div className="wrapper">
                <Sidebar color={"black"} image={""} routes={adminroutes} />
                <div className="main-panel" ref={null}>
                    <AdminNavbar />
                    <div className="container-bg">
                        <h2 className="wel-txt">Admin Profile</h2>
                        <div className="rank-card-block">
                            <div>
                                <div className="rank-block">

                                    {userInfo.rankIcon == null ? (
                                        <img src={require('../assets/img/dummy-img.png')} alt='logo' />
                                    ) : (
                                        <img src={"data:image/png;base64," + userInfo.rankIcon} alt="Logo" />
                                    )}
                                </div>

                            </div>
                            <div>
                                <h4>{userInfo.firstName + " " + userInfo.lastName}</h4>
                                <h6>Title: {userInfo.title}</h6>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminProfile;
