import React, { Component } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";

import logo from "../../assets/img/logo.png";


function Sidebar({ color, image, routes }) {
  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{
          backgroundImage: "url(" + image + ")"
        }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-center">
            <div className="logo-img">
              <img src={require("../../assets/img/logo.png")} alt="..." />
            </div>
        </div>

        <Nav>
        {routes.map((prop, index) => {
            if (!prop.redirect)
              return (
                <>
                <li className={ prop.upgrade ? "active active-pro" : prop.layout + prop.path } keyy={0}>
                  <NavLink to={prop.layout + prop.path} className="nav-link" id={prop.id}>
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
                
              </>
              );
            return null;
          })}
        </Nav>

      </div>
    </div>
  );
}

export default Sidebar;
