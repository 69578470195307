import React, {useState,useEffect} from 'react'
import {useNavigate, Link, input} from 'react-router-dom';
import {passwordReset} from '../service/UserService.js';
import Cookies from 'universal-cookie';

export default function  Passwordreset(props) {
    const navigate = useNavigate();

    const cookies = new Cookies();
            const [isValid, setIsValid] = useState(false);
            const [input, setInput] = useState({
                userName: '',
                currentPassword:null,
                password: '',                
                confirmPassword: ''
              });

              const [error, setError] = useState({
                username: '',
                password: '',
                confirmPassword: ''
              })

            const handleSubmit=(e)=>{
                e.preventDefault();
                var  value= document.getElementById("newpassword").value;
                  if (!validatePassword(value))
                  {
                    return false ;   
                  
                  }
                  var  value1= document.getElementById("confirmPassword").value;
                  if (!value1) {
                   return false; 
                  }
                  if(value!=value1){
                    return false; 
                  }

                 passwordReset(input).then((res) => {
                    console.log("response", res )
                    if(res.status===200){
                      document.getElementById("show").style.display="block";
                        
                    }
                    else{
                       props.loginFailure('Something Wrong!Please Try Again');
                    }

               }
             )
          }  
            useEffect(() =>{
              let search = window.location.search;
              let params = new URLSearchParams(search);
              let uName = params.get('userName');
              setInput(prev => ({
                ...prev,
                userName: uName
              }));

            },[]);
            const validatePassword = (password) => {
              const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
              return passwordPattern.test(password);
            }; 
            const onInputChange = e => {
                const { name, value } = e.target;
                setInput(prev => ({
                  ...prev,
                  [name]: value
                }));
                validateInput(e);
              }

              const validateInput = e => {
                let { name, value } = e.target;
                setError(prev => {
                  const stateObj = { ...prev, [name]: "" };
             
                  switch (name) {
                    case "username":
                      if (!value) {
                        stateObj[name] = "Please enter Username.";
                      }
                      break;
             
                    case "password":
                      if (!value) {
                        stateObj[name] = "Please enter Password.";
                      } else{
                      if (!validatePassword(value))
                        {
                          stateObj[name] = "Enter atleast min 8 characters: 1 uppercase, 1 lowercase, 1 number and 1 special character. Ex: Example@1";
                        }
                      }
                      break;
                    case "confirmPassword":
                      if (!value) {
                        stateObj[name] = "Please enter Confirm Password.";
                      } else if (input.password && value !== input.password) {
                        stateObj[name] = "Password and Confirm Password does not match.";
                      }
                      break;
             
                    default:
                      break;
                  }
              
                  return stateObj;
                });
                
              }
 return(
     <>
        <div className="wrapper pass-reset">
            <div className="signup-bg forgot-radious">
                <form onSubmit={handleSubmit}>
              
                    <h2> <img src={require("../assets/img/logo.png")} alt="Logo" />Password Reset</h2>
                    <div className="row">


<div className="col-md-12">
            <div className="form-group formelement-block">
                <label htmlFor="exampleInputEmail1">New Password</label>
                <input type="password" className="form-control" id="newpassword" placeholder="New Password" value={input.password}
          onChange={onInputChange} onBlur={validateInput} name="password"/>   
                {error.password && <span className='err'>{error.password}</span>}                  
           </div>
           </div>
           <div className="col-md-12">
            <div className="form-group formelement-block">
                <label htmlFor="exampleInputEmail1">Confirm Password</label>
                <input type="password" className="form-control" id="confirmPassword" placeholder="Confirm Password" value={input.confirmPassword}
                onChange={onInputChange} onBlur={validateInput} name="confirmPassword"/> 
                {error.confirmPassword && <span className='err'>{error.confirmPassword}</span>}                    
           </div>
           </div>

                    </div>
                     <p><button type="submit" onSubmit={validateInput} className="btn submit-btn">Submit</button></p>
                </form>
            </div>
            <div className="" id="show" style={{display:"done"}}><div> Password Created Succesfully. </div><a href="LoginPage">OK</a></div>
        </div>
     </>
   )

}
