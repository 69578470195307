
const appRoutes = [
  {
    path: "/profile",
    name: "Profile",
    icon: "nc-icon nc-circle-09",
    layout: "/admin",
    id:"profile"
  },
  {
    path: "/user",
    name: "Personal Information",
    icon: "nc-icon nc-circle-09",
    layout: "/admin",
    id:"user"
  },
  {
    path: "/training",
    name: "Training Experience",
    icon: "nc-icon nc-circle-09",
    layout: "/admin",
    id:"training"    
  },
  {
    path: "/upload",
    name: "DD-214 File Upload",
    icon: "nc-icon nc-circle-09",
    layout: "/admin",
    id:"upload"   
  },
  {
    path: "/resumeupload",
    name: "Resume Upload",
    icon: "nc-icon nc-circle-09",
    layout: "/admin",
    id:"resumeupload"   
  }   
];

export default appRoutes;