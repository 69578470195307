import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Alert, Spinner } from "react-bootstrap";
import { signup } from "../service/UserService.js";

import {
  branch,
  states,
  rank,
} from "../service/UserService.js";

export default class Signup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      firstName: "",
      lastName: "",
      emailId: "",
      phoneNumber: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipCode: "",
      branch: "",
      rank: "",
      yearsOfService: "",
      mos: "",
      responseData: "",
      branchData: [],
      statesData: [],
      cityData: [],
      rankData: [],
      states: "",
      email: "",
      isValid: false,
      password: "",
      valid: false,
      validateEmail: "",
      validateCity: ""
    };
  }
  handleSubmit = (event) => {
    event.preventDefault();
    const user = {
      id: "",
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      emailId: this.state.emailId,
      phoneNumber: this.state.phoneNumber,
      address1: this.state.address1,
      address2: this.state.address2,
      city: this.state.city,
      state: this.state.state,
      zipCode: this.state.zipCode,
      branch: this.state.branch,
      rank: this.state.rank,
      yearsOfService: this.state.yearsOfService,
      mos: this.state.mos,
    };
    console.log(user);
    if (this.validateSubmit()) {
      signup(JSON.stringify(user))
        .then((response) => {
          console.log("response", response);
          if (response.status === 200) {
            console.log("Udated");
            this.responseData = response.data;
            this.setuserExist(1);
            document.getElementById("show").style.display = "block";
          } else {
            console.log("Something Wrong!Please Try Again");
          }
        })
        .catch((err) => {
          if (err && err.response) {
            switch (err.response.status) {
              case 403:
                document.getElementById("msg").innerHTML = err.response.data;
                document.getElementById("show1").style.display = "block";
                break;
              case 400:
                document.getElementById("msg").innerHTML = err.response.data;
                document.getElementById("show1").style.display = "block";
                break;
              case 412:
                document.getElementById("msg").innerHTML = err.response.data;
                document.getElementById("show1").style.display = "block";
                break;
              default:
                console.log("Something Wrong!Please Try Again");
                console.log(err);
            }
            this.setuserExist(0);
          } else {
            console.log("Something Wrong!Please Try Again");
            console.log(err);
          }
        });
    }
  };
  setuserExist = (val) => {
    if (val == 0) {
      document.getElementById("userExist").href = "javascript:void(0);";
    } else {
      document.getElementById("userExist").href = "LoginPage";
    }
  };
  clickOK() {
    document.getElementById("show1").style.display = "none";
  }

  handleChange = (event) => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });
    this.inputBlur(event);
  };

  componentDidMount() {
    states().then((response) => {
      this.setState({
        statesData: response.data,        
      });
    });
    branch().then((response) => {
      this.setState({
        branchData: response.data,
      });
    });

    rank("?branchId=2").then((response) => {
      console.log(response);
      this.setState({
        rankData: response.data,
      });
    });
  }

  handleChangeZip = (e) => {
    const val = e.target.value;
    const max = 9999;
    const maxLength = max.toString().length - 1;
    if (val > max) {
      e.preventDefault();
    }
  };

  handleChangeMobile = (e) => {
    let val = e.target.value;
    val = val.trim().replace(/[^0-9]/g, "");
    if (val.length < 4) val = val;
    if (val.length < 7) val = val.replace(/(\d{3})(\d{1})/, "$1-$2");
    if (val.length < 11) val = val.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    val = val.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    e.target.value = val;
  };

  handleChangeYear = (e) => {
    const val = e.target.value;
    const max = 9;
    const maxLength = max.toString().length - 1;
    if (val > max) {
      e.preventDefault();
    }
  };

  bindCity = (event) => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });
  };

  bindRank = (event) => {
    this.state.branch = event.target.value;
    if (event.target.value != "undefined") {
      rank("?branchId=" + event.target.value).then((response) => {
        console.log(response);
        this.setState({
          rankData: response.data,
        });
      });
    }
  };

  validateSubmit = (e) => {
    var value = document.getElementById("firstName").value;

    if (!value) {
      document.getElementById("errorMessage").style.display = "block";
      return false;
    } else {
      document.getElementById("errorMessage").style.display = "none";
    }
    var value = document.getElementById("lastName").value;
    if (!value) {
      document.getElementById("errorMessage1").style.display = "block";
      return false;
    } else {
      document.getElementById("errorMessage1").style.display = "none";
    }
    var value = document.getElementById("phoneNumber").value;
    if (!value) {
      document.getElementById("errorMessage2").style.display = "block";
      return false;
    } else {
      document.getElementById("errorMessage2").style.display = "none";
    }

    value = document.getElementById("email").value;
    if (!value) {
      document.getElementById("errorMessage3").style.display = "block";
      return false;
    } else {
      if (!this.validateEmail(value)) {
        document.getElementById("errorMessage3").style.display = "block";
        return false;
      }
    }

    var value = document.getElementById("city").value;
    if (!value) {
      document.getElementById("errorMessage4").style.display = "block";
      return false;
    } else {
      if (!this.validateCity(value)) {
        document.getElementById("errorMessage4").style.display = "block";
        return false;
      }      
    }

    return true;
  };

  inputBlur = (e) => {
    var value = document.getElementById("firstName").value;
    if (value != "") {
      document.getElementById("errorMessage").style.display = "none";
    } else {
      document.getElementById("errorMessage").style.display = "block";
      return false;
    }
    var value = document.getElementById("lastName").value;
    if (value != "") {
      document.getElementById("errorMessage1").style.display = "none";
    } else {
      document.getElementById("errorMessage1").style.display = "block";
      return false;
    }
    var value = document.getElementById("phoneNumber").value;
    if (value != "") {
      document.getElementById("errorMessage2").style.display = "none";
    } else {
      document.getElementById("errorMessage2").style.display = "block";
      return false;
    }

    value = document.getElementById("email").value;
    if (!value) {
      document.getElementById("errorMessage3").style.display = "block";
      return false;
    } else {
      if (!this.validateEmail(value)) {
        document.getElementById("errorMessage3").style.display = "block";
        return false;
      }
    }

    var value = document.getElementById("city").value;
    if (!value) {
      document.getElementById("errorMessage4").style.display = "none";
    } else {
      if (!this.validateCity(value)) {
        document.getElementById("errorMessage4").style.display = "block";
        return false;
      }
    }
  };

  validateEmail = (email) => {
    const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return emailPattern.test(email);
  };
  
  validateCity = (city) => {
    const alphabeticRegex = /^[A-Za-z\s]+$/;
    return alphabeticRegex.test(city);
  };

  handleBlur = (event) => {
    const { value } = event.target;
    const isValid = this.validateEmail(value);
    this.setState({ isValid });
  };

  handleCityBlur = (event) => {
    const { value } = event.target;
    const isValid = this.validateCity(value);
    this.setState({ isValid });
  };
  
  validatePassword = (password) => {
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return passwordPattern.test(password);
  };
  render() {
    return (
      <>
        <div className="wrapper">
          <form onSubmit={this.handleSubmit}>
            <div className="signup-bg sign-border">
              <h2>
                <img src={require("../assets/img/logo.png")} alt="Logo" />
                Personal Information
              </h2>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group formelement-block">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="firstName"
                      placeholder="First Name"
                      onChange={this.handleChange}
                      id="firstName"
                      onBlur={this.inputBlur}
                    />
                    <div id="errorMessage">
                      <p className="error-msg">*please enter first name</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group formelement-block">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      placeholder="Last Name"
                      onChange={this.handleChange}
                      id="lastName"
                      onBlur={this.inputBlur}
                    />
                    <div id="errorMessage1">
                      <p className="error-msg">*please enter last name</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group formelement-block">
                    <label htmlFor="phoneNumber">Phone</label>
                    <input
                      type="tel"
                      className="form-control"
                      min="0"
                      maxLength={12}
                      name="phoneNumber"
                      placeholder="123-453-6783"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      onChange={this.handleChange}
                      onKeyPress={this.handleChangeMobile}
                      id="phoneNumber"
                    />
                    <div id="errorMessage2">
                      <p className="error-msg">*please enter phone number</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group formelement-block">
                    <label htmlFor="emailId">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      onBlur={this.handleBlur}
                      name="emailId"
                      onChange={this.handleChange}
                      placeholder="Email"
                      id="email"
                    />
                    <div id="errorMessage3">
                      <p c>
                        {this.state.isValid ? (
                          <div></div>
                        ) : (
                          <div>Invalid email address</div>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group formelement-block">
                    <label htmlFor="address1">Address1</label>
                    <input
                      type="text"
                      className="form-control"
                      name="address1"
                      placeholder="Address1"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group formelement-block">
                    <label for="address2">Address2</label>
                    <input
                      type="text"
                      className="form-control"
                      name="address2"
                      placeholder="Address2"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group formelement-block">
                    <label htmlFor="state">State</label>
                    <select
                      className="selectpicker des form-control"
                      data-show-subtext="false"
                      name="state"
                      data-live-search="true"
                      onChange={this.bindCity}                      
                    >
                      {this.state.statesData.map((d, index) => (
                        <option key={d.stateId} value={d.stateId}>
                          {d.stateName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group formelement-block">
                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      placeholder="City"
                      id="city"
                      onChange={this.handleChange}
                      value={this.state.city}
                      onBlur={this.handleCityBlur} 
                    />
                    <div id="errorMessage4">
                      <p className="error-msg">
                        {this.state.isValid ? (
                          <div></div>
                        ) : (
                          <div>please enter valid city name</div>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group formelement-block">
                    <label htmlFor="zipCode">Zipcode</label>
                    <input
                      type="number"
                      className="form-control"
                      min="0"
                      id="zipCode"
                      name="zipCode"
                      placeholder="Zipcode"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onKeyPress={this.handleChangeZip}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group formelement-block">
                    <label for="branch">Military Branch</label>
                    <select
                      className="selectpicker des form-control"
                      data-show-subtext="false"
                      data-live-search="true"
                      name="branch"
                      onChange={this.bindRank}
                    >
                      {this.state.branchData.map((d, index) => (
                        <option key={d.branchId} value={d.branchId}>
                          {d.branchName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group formelement-block">
                    <label htmlFor="rank">Rank</label>
                    <select
                      className="selectpicker des form-control"
                      data-show-subtext="false"
                      data-live-search="true"
                      name="rank"
                      onChange={this.handleChange}
                    >
                      {this.state.rankData.map((d, index) => (
                        <option key={d.rankId} value={d.rankId}>
                          {d.rankName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group formelement-block">
                    <label htmlFor="yearsOfService">Years Of Service</label>
                    <input
                      type="number"
                      className="form-control"
                      min="0"
                      name="yearsOfService"
                      placeholder="Years Of Service"
                      onChange={this.handleChange}
                      onKeyPress={this.handleChangeYear}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group formelement-block">
                    <label htmlFor="mos">MOS</label>
                    <textarea
                      rows={4}
                      cols={50}
                      className="form-control"
                      placeholder="MOS ID, MOS ID1, MOS ID2 ...."
                      name="mos"
                      onChange={this.handleChange}
                    ></textarea>
                  </div>
                </div>
                <div
                  className="col-md-12"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  <button type="submit" className="btn btn-primary update-btn">
                    SignUp
                  </button>
                  <p style={{ paddingTop: "20px" }}>
                    If you have an account? <Link to="/LoginPage">Sign in</Link>
                  </p>
                </div>
              </div>
            </div>
          </form>
          {this.responseData && (
            <Alert style={{ marginTop: "20px" }} variant="danger">
              {this.responseData}
            </Alert>
          )}
          <div className="" id="show" style={{ display: "done" }}>
            <div>Succesfully Submited. Please check your email. </div>
            <a href="LoginPage">OK</a>
          </div>
        </div>
        <div className="" id="show1" style={{ display: "none" }}>
          <div id="msg"> Password changed succesfully. </div>
          <a id="userExist" onClick={this.clickOK}>
            OK
          </a>
        </div>
      </>
    );
  }
}
