import React, { useState, useEffect } from "react";
import { useNavigate, Link, input } from "react-router-dom";
import AdminNavbar from "../component/Navbars/AdminNavbar";
import { Alert, Spinner } from "react-bootstrap";
import Sidebar from "../component/Sidebar/Sidebar";
import routes from "../routes.js";
import Cookies from "universal-cookie";
import {
  updateUserData,
  branch,
  states,
  rank,
  viewUserData,
} from "../service/UserService.js";

export default function User(props) {
  const navigate = useNavigate();
  var validValue = true;
  const cookies = new Cookies();
  var responseData;

  const [input, setInput] = useState({
    id: "",
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    branch: "",
    rank: "",
    yearsOfService: "",
    mos: "",
    states: "",
  });
  const [arrayData, SetArrayData] = useState({
    branchData: [],
    statesData: [],
    cityData: [],
    rankData: [],
  });
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
  });
  const handleSubmit = async (event) => {
    event.preventDefault();
    var value = document.getElementById("firstName").value;
    await validateForm("firstName", value);
    value = document.getElementById("lastName").value;
    await validateForm("lastName", value);
    value = document.getElementById("phoneNumber").value;
    await validateForm("phoneNumber", value);
    value = document.getElementById("emailId").value;
    await validateForm("emailId", value);
    if (!validValue) {
      return false;
    }
    const user = {
      id: input.emailId,
      firstName: input.firstName,
      lastName: input.lastName,
      emailId: input.emailId,
      phoneNumber: input.phoneNumber,
      address1: input.address1,
      address2: input.address2,
      city: input.city,
      state: input.state,
      zipCode: input.zipCode,
      branch: input.branch,
      rank: input.rank,
      yearsOfService: input.yearsOfService,
      mos: input.mos,
    };
    console.log("Input");
    console.log(user);

    updateUserData(JSON.stringify(user))
      .then((response) => {
        if (response.status === 200) {
          console.log("Updated");
          responseData = response.data;
          document.getElementById("show").style.display = "block";
        } else {
          console.log("Something Wrong!Please Try Again");
        }
      })
      .catch((err) => {
        if (err && err.response) {
          switch (err.response.status) {
            case 403:
              console.log("403 status");
              break;
            default:
              console.log("Something Wrong!Please Try Again");
          }
        } else {
          console.log("Something Wrong!Please Try Again");
        }
      });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    inputBlur(event);
  };
  useEffect(() => {
    var Key = localStorage.getItem("USER_KEY");
    if (Key != undefined) {
    } else {
      window.location.href = "../login";
    }

    var name = cookies.get("userName");

    var tempState;
    var tempBranch;
    viewUserData("?userName=" + name).then((response) => {
      console.log("response", response);
      if (response.status === 200) {
        setInput((prev) => ({
          ...prev,
          id: "",
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          emailId: response.data.emailId,
          phoneNumber: response.data.phoneNumber,
          address1: response.data.address1,
          address2: response.data.address2,
          city: response.data.city,
          state: response.data.state,
          zipCode: response.data.zipCode,
          branch: response.data.branch,
          rank: response.data.rankId,
          yearsOfService: response.data.yearsOfService,
          mos: response.data.mos,
        }));

        if (response.data.state == "") {
          tempState = 1;
        } else {
          tempState = response.data.state;
        }
 
        if (response.data.branch == "") {
          tempBranch = 2;
        } else {
          tempBranch = response.data.branch;
        }
        bindRankwithBranch(tempBranch);

      } else {
        // props.loginFailure('Something Wrong!Please Try Again');
      }
    });
    states().then((response) => {
      SetArrayData((prev) => ({
        ...prev,
        statesData: response.data,
      }));
    });
    branch().then((response) => {
      SetArrayData((prev) => ({
        ...prev,
        branchData: response.data,
      }));
    });

    setInput((prev) => ({
      ...prev,
      userName: name,
    }));
  }, []);

  const bindRankwithBranch = (branchId) => {
    rank("?branchId=" + branchId).then((response) => {
      console.log(response);
      SetArrayData((prev) => ({
        ...prev,
        rankData: response.data,
      }));
    });
  };
  const handleChangeZip = (e) => {
    const val = e.target.value;
    const max = 9999;
    const maxLength = max.toString().length - 1;
    if (val > max) {
      e.preventDefault();
    }
  };

  const handleChangeMobile = (e) => {
    let val = e.target.value;
    val = val.trim().replace(/[^0-9]/g, "");
    if (val.length < 4) val = val;
    if (val.length < 7) val = val.replace(/(\d{3})(\d{1})/, "$1-$2");
    if (val.length < 11) val = val.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    val = val.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    e.target.value = val;
  };
  const handleChangeYear = (e) => {
    const val = e.target.value;
    const max = 9;
    const maxLength = max.toString().length - 1;
    if (val > max) {
      e.preventDefault();
    }
  };

  const bindRank = (event) => {
    setInput((prev) => ({
      ...prev,
      branch: event.target.value,
    }));
    if (event.target.value != "undefined") {
      rank("?branchId=" + event.target.value).then((response) => {
        console.log(response);
        SetArrayData((prev) => ({
          ...prev,
          rankData: response.data,
        }));
        setInput((prev) => ({
          ...prev,
          rank: response.data[0].rankId,
        }));
      });
    }
  };
  const inputBlur = (e) => {
    let { name, value } = e.target;
    validateForm(name, value);
  };
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  const validateCity = (city) => {
    const alphabeticRegex = /^[A-Za-z\s]+$/;
    return alphabeticRegex.test(city);
  };

  const onClickOk = (e) => {
    document.getElementById("show").style.display = "none";
  };
  const validateForm = (name, value) => {
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "firstName":
          if (!value) {
            stateObj[name] = "*please enter First name.";
            validValue = false;
          }
          break;

        case "lastName":
          if (!value) {
            stateObj[name] = "*please enter Last name.";
            validValue = false;
          }
          break;

        case "phoneNumber":
          if (!value) {
            stateObj[name] = "*please enter Phone number";
            validValue = false;
          }
          break;

        case "emailId":
          if (!value) {
            stateObj[name] = "*please enter EMail Id";
            validValue = false;
          } else {
            if (!validateEmail(value)) {
              validValue = false;
              stateObj[name] = "Please Enter valid EMail Id";
            }
          }
          break;

          case "city":
          if (!value) {
            stateObj[name] = "*please enter city";
            validValue = false;
          } else {
            if (!validateCity(value)) {              
              stateObj[name] = "Please enter valid city";
              validValue = false;
            }
          }
          break;

        default:
          break;
      }
      return stateObj;
    });
  };
  return (
    <>
      <div className="wrapper">
        <Sidebar color={"black"} image={""} routes={routes} />
        <div className="main-panel" ref={null}>
          <AdminNavbar />
          <div className="content">
            <form onSubmit={handleSubmit}>
              <div className="signup-bg">
                <h2>Personal Information</h2>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group formelement-block">
                      <label htmlFor="firstName">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        placeholder="First Name"
                        value={input.firstName}
                        onChange={handleChange}
                        onBlur={inputBlur}
                        name="firstName"
                      />

                      <div id="errorMessage" style={{ display: "none" }}>
                        <p className="error-msg">*please enter first name</p>
                      </div>
                      {error.firstName && (
                        <span className="err">{error.firstName}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group formelement-block">
                      <label htmlFor="lastName">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        placeholder="Last Name"
                        onChange={handleChange}
                        value={input.lastName}
                        id="lastName"
                        onBlur={inputBlur}
                      />
                      <div id="errorMessage1" style={{ display: "none" }}>
                        <p className="error-msg">*please enter last name</p>
                      </div>
                      {error.lastName && (
                        <span className="err">{error.lastName}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group formelement-block">
                      <label htmlFor="phoneNumber">Phone</label>
                      <input
                        type="tel"
                        className="form-control"
                        name="phoneNumber"
                        maxLength={12}
                        placeholder="Phone"
                        onChange={handleChange}
                        onKeyPress={handleChangeMobile}
                        value={input.phoneNumber}
                        id="phoneNumber"
                        onBlur={inputBlur}
                      />
                      <div id="errorMessage2" style={{ display: "none" }}>
                        <p className="error-msg">*please enter phone number</p>
                      </div>
                      {error.phoneNumber && (
                        <span className="err">{error.phoneNumber}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group formelement-block">
                      <label htmlFor="emailId">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="emailId"
                        onChange={handleChange}
                        placeholder="Email"
                        value={input.emailId}
                        id="emailId"
                        disabled
                      />
                      {/* <div id="errorMessage3"  style={{display:'none'}}><p className="error-msg">{input.isValid ? (<div></div>) : (<div>Invalid email address</div>)}</p></div> */}
                      {error.emailId && (
                        <span className="err">{error.emailId}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group formelement-block">
                      <label htmlFor="address1">Address1</label>
                      <input
                        type="text"
                        className="form-control"
                        name="address1"
                        placeholder="Address1"
                        onChange={handleChange}
                        value={input.address1}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group formelement-block">
                      <label htmlFor="address2">Address2</label>
                      <input
                        type="text"
                        className="form-control"
                        name="address2"
                        placeholder="Address2"
                        onChange={handleChange}
                        value={input.address2}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group formelement-block">
                      <label htmlFor="state">State</label>
                      <select
                        className="selectpicker des form-control"
                        data-show-subtext="false"
                        name="state"
                        data-live-search="true"
                        value={input.state}
                        onChange={handleChange}
                        onBlur={inputBlur}
                      >
                        {arrayData.statesData.map((d, index) => (
                          <option key={d.stateId} value={d.stateId}>
                            {d.stateName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group formelement-block">
                      <label htmlFor="city">City</label>
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        placeholder="City"
                        id="city"
                        value={input.city}
                        onChange={handleChange}
                        onBlur={inputBlur}
                      />
                      {error.city && (
                        <span className="err">{error.city}</span>
                      )}
                    </div>              
                  </div>
                  <div className="col-md-4">
                    <div className="form-group formelement-block">
                      <label htmlFor="zipCode">Zipcode</label>
                      <input
                        type="number"
                        className="form-control"
                        min="0"
                        name="zipCode"
                        placeholder="Zipcode"
                        onChange={handleChange}
                        onKeyPress={handleChangeZip}
                        value={input.zipCode}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group formelement-block">
                      <label htmlFor="branch">Military Branch</label>
                      <select
                        className="selectpicker des form-control"
                        data-show-subtext="false"
                        data-live-search="true"
                        name="branch"
                        onChange={bindRank}
                        value={input.branch}
                      >
                        {arrayData.branchData.map((d, index) => (
                          <option key={d.branchId} value={d.branchId}>
                            {d.branchName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group formelement-block">
                      <label htmlFor="rank">Rank</label>
                      <select
                        className="selectpicker des form-control"
                        data-show-subtext="false"
                        data-live-search="true"
                        name="rank"
                        onChange={handleChange}
                        value={input.rank}
                      >
                        {arrayData.rankData.map((d, index) => (
                          <option key={d.rankId} value={d.rankId}>
                            {d.rankName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group formelement-block">
                      <label htmlFor="yearsOfService">Years Of Service</label>
                      <input
                        type="number"
                        className="form-control"
                        name="yearsOfService"
                        min="0"
                        placeholder="Years Of Service"
                        onChange={handleChange}
                        value={input.yearsOfService}
                        onKeyPress={handleChangeYear}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group formelement-block">
                      <label htmlFor="mos">MOS</label>
                      <textarea
                        rows={4}
                        cols={50}
                        className="form-control"
                        placeholder="MOS ID, MOS ID1, MOS ID2 ...."
                        name="mos"
                        onChange={handleChange}
                        value={input.mos}
                      ></textarea>
                    </div>
                  </div>
                  <div
                    className="col-md-12"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <button
                      type="submit"
                      className="btn btn-primary update-btn"
                    >
                      Update
                    </button>
                    {responseData && (
                      <Alert style={{ marginTop: "20px" }} variant="danger">
                        {this.responseData}
                      </Alert>
                    )}
                    <div className="" id="show" style={{ display: "done" }}>
                      <div>Changes got saved succesfully. </div>
                      {/* <a href={"/admin/user"} >OK</a>  */}
                      <a onClick={onClickOk}>OK</a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
