import React from 'react'
import {useNavigate, Link} from 'react-router-dom';
export default function Admindashboard() {


return(
    <>
<div className="wrapper">
    <div className="signup-bg" style={{display:"flex",padding:"0"}}>
        <div className="left-pannel">
        <ul style={{margin:"0",padding:"0",listStyleType: "none"}}>
          <li><Link to="/admin/creataccount">Admin Create Account</Link></li>
          <li><Link to="/admin/admindashboard" className="active" target="_self">Admin Dashboard</Link></li>
          <li><Link to="/admin/admindashboardveterans" target="_self">Veterans Dashboard</Link></li>
          <li><Link to="/admin/admindashboardeducator" target="_self">Educators Dashboard </Link></li>
          <li><Link to="/admin/educatorcreationaccount"target="_self">Educator Create Account</Link></li>
          <li><Link to="/admin/educationprofile" target="_self">Educator Profile</Link></li>
        </ul>
        </div>
      <div className="right-pannel">
        <form>
            <h2><img src={require('../assets/img/logo.png')} alt="Logo" />Admin Dashboard</h2>
            <div className="row" style={{width:"100%",paddingBottom:"20px"}}>
              <div className="col-md-6"> 
                <div class="form-check">
                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked/>
                    <label className="form-check-label" htmlFor="exampleRadios1">
                        Veteran Profile
                    </label>
                </div>
              </div>
              <div className="col-md-6">
              <div class="form-check">
                      <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2"/>
                      <label className="form-check-label" htmlFor="exampleRadios2">
                      Educator Profile
                      </label>
              </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                  <div className="form-group formelement-block">
                    <label htmlFor="exampleInputEmail1">List of Veterans</label>
                    <select className="form-control" id="veteransList">
                      <option>New</option>
                      <option>DD-214 Received</option>
                      <option>Applications Submitted</option>
                      <option>Accepted Offer</option>
                    </select>
                    
                  </div>
              </div>
              <div className="col-md-6">
                  <div className="form-group formelement-block">
                      <label htmlFor="exampleInputEmail1">List of Educators</label>
                      <select className="form-control" id="viewoffers">
                        <option>Number of Veterans Applied</option>
                        <option>Number of Veterans Rejections</option>
                        <option>Number of Veterans Offers</option>
                        <option>Number of Accepted Veteran </option>
                        <option>Offers for each Educator</option>
                    </select>
                    
                    </div>
              </div>
              <div className="col-md-12" style={{marginBottom:"20px"}}>
              <div class="input-group">
                <input className="form-control py-2" type="text" placeholder="Search List of Expired Offers by Veteran Name or Educator Name"/>
                <span className="input-group-append">
                  <button className="btn btn-outline-secondary" type="button">
                      <i className="fa fa-search"></i>
                  </button>
                </span>
          </div>
              </div>   
        
              <div className="col-md-12" style={{width:"100%"}}>
              <button type="submit" className="btn btn-primary sign-btn">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
</div>
    
    </>
)
}