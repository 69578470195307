import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Sidebar from "../component/Sidebar/Sidebar";
import routes from "../routes.js";
import adminroutes from "../views/adminroutes.js";
import educatorroutes from "../views/educator/educatorroutes.js";
import AdminNavbar from "../component/Navbars/AdminNavbar";
import { passwordReset } from "../service/UserService";
import Cookies from "universal-cookie";
import { viewUserData } from "../service/UserService.js";

export default function Changepassword(props) {
  const navigate = useNavigate();
  const cookies = new Cookies();
  var validValue = true;
  const [values, setValues] = useState({    
    userName: "",
    currentPassword: "",
    Password: "",
    confirmPassword: "",
  });
  const [input, setInput] = useState({
    userName: "",
    currentPassword: "",
    Password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    userName: "",
    currentPassword: "",
    Password: "",
    confirmPassword: "",
  });

  const [userInfo, setUser] = useState({
    userName: "",
    title: "",
    institutionName: ""
  });
  useEffect(() => {
    var Key = localStorage.getItem("USER_KEY");
    if (Key != undefined) {
      var name = cookies.get("userName");
      viewUserData("?userName=" + name).then((response) => {
        console.log("response", response);
        if (response.status === 200) {
          setUser({
            ...userInfo,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            title: response.data.title,
            institutionName: response.data.institutionName
          });
        }
      });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    var value = document.getElementById("currentPassword").value;
    await validateForm("currentPassword", value);
    value = document.getElementById("password").value;
    await validateForm("password", value);
    value = document.getElementById("confirmPassword").value;
    await validateForm("confirmPassword", value);
    if (!validValue) return false;
    var name = cookies.get("userName");
    input.userName = name;

    passwordReset(input)
      .then((res) => {
        console.log("response", res);
        if (res.status === 200) {
          document.getElementById("show").style.display = "block";
        } else {
          alert("Something Wrong!Please Try Again");
        }
      })
      .catch((err) => {
        if (err && err.response) {
          switch (err.response.status) {
            case 401:
              console.log("401 status");
              document.getElementById("msg").innerHTML = err.response.data;
              document.getElementById("show1").style.display = "block";
              break;
            default:
              document.getElementById("msg").innerHTML = err.response.data;
              document.getElementById("show1").style.display = "block";
          }
        } else {
          document.getElementById("msg").innerHTML = err.response.data;
          document.getElementById("show1").style.display = "block";
        }
      });
  };

  const validateSubmit = async (e) => {
    var name = cookies.get("userName");
    input.userName = name;
    return true;
  };
  const onClickOk = (e) => {
    document.getElementById("show1").style.display = "none";
    setInput((prev) => ({
      ...prev,
      currentPassword: "",
      password: "",
      confirmPassword: "",
    }));
  };
  useEffect(() => {
    var Key = localStorage.getItem("USER_KEY");
    if (Key != undefined) {
    } else {
      navigate("../login");
    }

    setInput((prev) => ({
      ...prev,
      userName: "",
    }));
  }, []);
  const validatePassword = (password) => {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordPattern.test(password);
  };
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));

    validateInput(e);
  };
  const validateInput = (e) => {
    let { name, value } = e.target;
    validateForm(name, value);
  };
  const validateForm = (name, value) => {
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "currentPassword":
          if (!value) {
            validValue = false;
            stateObj[name] = "Please enter Current Password.";
          }
          break;

        case "password":
          if (!value) {
            validValue = false;
            stateObj[name] = "Please enter New Password.";
          } else if (!validatePassword(value)) {
            validValue = false;
            stateObj[name] =
              "Enter atleast min 8 characters: 1 uppercase, 1 lowercase, 1 number and 1 special character. Ex: Example@1";
          }
          break;

        case "confirmPassword":
          if (!value) {
            validValue = false;
            stateObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            validValue = false;
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }
      return stateObj;
    });
  };

  return (
    <>
      <div className="wrapper">

      {
     userInfo.title !== null
     ? (
      <Sidebar color={"black"} image={""} routes={adminroutes} />
     ) : userInfo.institutionName !== null
     ? ( <Sidebar color={"black"} image={""} routes={educatorroutes} /> )
     : (
      <Sidebar color={"black"} image={""} routes={routes} />
     )
   }

      <div className="main-panel" ref={null}>
            <AdminNavbar />
          
          <div className="signup-bg">
            <form onSubmit={handleSubmit}>
              <h2>Change Password</h2>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group formelement-block">
                    <label htmlFor="exampleInputEmail1">Current Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="currentPassword"
                      placeholder="Current Password"
                      onChange={onInputChange}
                      value={input.currentPassword}
                      name="currentPassword"
                    />
                    {error.currentPassword && (
                      <span className="err">{error.currentPassword}</span>
                    )}
                    <p
                      className=""
                      id="errorMessage"
                      style={{ display: "none" }}
                    >
                      Please enter current password
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group formelement-block">
                    <label htmlFor="exampleInputEmail1">New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      placeholder="New Password"
                      value={input.password}
                      onChange={onInputChange}
                      onBlur={validateInput}
                      name="password"
                    />
                    {error.password && (
                      <span className="err">{error.password}</span>
                    )}
                    <p
                      className=""
                      id="errorMessage1"
                      style={{ display: "none" }}
                    >
                      Please enter New password
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group formelement-block">
                    <label htmlFor="exampleInputEmail1">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      value={input.confirmPassword}
                      onChange={onInputChange}
                      onBlur={validateInput}
                      name="confirmPassword"
                    />
                    <p
                      className=""
                      id="errorMessage2"
                      style={{ display: "none" }}
                    >
                      Please enter confirm password
                    </p>
                    {error.confirmPassword && (
                      <span className="err">{error.confirmPassword}</span>
                    )}
                  </div>
                </div>
              </div>
              <p
                className=""
                id="errorMessage3"
                style={{ display: "none" }}
              ></p>
              <p>
                <button type="submit" className="btn submit-btn">
                  Submit
                </button>
              </p>
            </form>
            <div className="" id="show" style={{ display: "none" }}>
              <div>Password changed succesfully. </div>
              <a href="LoginPage">OK</a>
            </div>
            <div className="" id="show1" style={{ display: "none" }}>
              <div id="msg"> Password changed succesfully. </div>
              <a onClick={onClickOk}>OK</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
