import React, { useState, useEffect } from "react";
import Sidebar from "../../component/Sidebar/Sidebar";
import AdminNavbar from "../../component/Navbars/AdminNavbar";
import routes from "../routes.js";
import adminroutes from "../adminroutes.js";
import { getAllUploadStatuses } from "../../service/UserService.js";
import Cookies from "universal-cookie";
import { useNavigate, Link } from "react-router-dom";
import { viewUserData, getAllVeteransByStatus } from "../../service/UserService.js";


export default function AdminDashboard(props) {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [arrayData, setArrayData] = useState({
    statusData: [],
  });
  const [userInfo, setUser] = useState({
    userName: "",
    title: "",
  });  

  useEffect(() => {
    var Key = localStorage.getItem("USER_KEY");
    if (Key != undefined) {
      var name = cookies.get('userName')
      viewUserData("?userName=" + name).then((response) => {
        console.log("response", response);
        if (response.status === 200) {
          setUser({
            ...userInfo,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            title: response.data.title,
          });
        }
      });

      getAllUploadStatuses().then((response) => {
        console.log("response", response);
        if (response.status === 200) {
          console.log("response.data", response.data);
          setArrayData((prev) => ({
            ...prev,
            statusData: response.data,
          }));
        }
      });
    } else {
      navigate("../login");
    }
  }, []);

  const handleSubmit = async(evt)=>{
    evt.preventDefault();
    
    var status = document.getElementById("veteransList").value;
    
    getAllVeteransByStatus('?status=' + status).then((response)=>{
        console.log("response",response);
        if(response.status===200){          
          navigate('/admin/VeteranDashboardView', {state: response.data});            
        }
        else{           
           console.log('Something Wrong! Please Try Again');
        }
    }).catch((err)=>{
        if(err && err.response){

        switch(err.response.status){
            case 401:
                console.log("401 status");
                console.log('Something Wrong! Please Try Again');
                break;
            default:
              console.log('Something Wrong! Please Try Again');

        }

        }
        else{
          console.log('Something Wrong!Please Try Again');
        }
    });
}

  return (
    <>
      <div className="wrapper">
      {userInfo.title !== null ? (
        <Sidebar color={"black"} image={""} routes={adminroutes} />
        ) : (
          <Sidebar color={"black"} image={""} routes={routes} />
        )}
        
        <div className="main-panel" ref={null}>
          <AdminNavbar />
          <div className="signup-bg" >
          <div className="right-pannel">
            <form onSubmit={handleSubmit} noValidate={false}>
              <h2 className="wel-txt">Admin Dashboard</h2>

              <div
                className="row"
                style={{ width: "100%", paddingBottom: "20px" }}
              >
                <div className="col-md-6">
                  <div class="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios1"
                      value="option1"
                      checked
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios1"
                    >
                      Veteran Profile
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group formelement-block">
                    <label htmlFor="exampleInputEmail1">List of Veterans</label>
                    <select
                      className="selectpicker des form-control"
                      id="veteransList"
                      data-show-subtext="false"
                      data-live-search="true"
                    >
                      {arrayData.statusData.map((d, index) => (
                        <option key={d.id} value={d.status}>
                          {d.status}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-12" style={{ width: "100%" }}>
                  <button type="submit" className="btn submit-btn">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}
